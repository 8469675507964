// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import App from './App.vue'
import router from './router'
import store from './store/index'
import axios from 'axios'
import { i18n, useI18n } from './js/i18n/lang'
// axios.defaults.baseURL = 'http://messagepush-msfmanagement-cluster01.axa-dev.wise-paas.top'
// axios.defaults.headers.common['authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb3VudHJ5IjoiIiwiY3JlYXRpb25UaW1lIjoxNjc2NTA5MDk5LCJleHAiOjE2NzY1MTI2OTksImZpcnN0TmFtZSI6ImFkbWluIiwiaWF0IjoxNjc2NTA5MDk5LCJpZCI6IjEyMDA2NGZiLTYxNDctNGY1MS05OGEwLTg5MjI0NGIwZGI1NiIsImlzcyI6Indpc2UtcGFhcyIsImxhc3RNb2RpZmllZFRpbWUiOjE2Njk3MDQ1MTIsImxhc3ROYW1lIjoiYWRtaW4iLCJyZWZyZXNoVG9rZW4iOiJkZTgyYjE4Yi1hY2QyLTQxZGEtYjQwNy1lOTQxNjg0NTE1NTkiLCJzdGF0dXMiOiIiLCJ1c2VybmFtZSI6ImFkbWluQGFkdmFudGVjaC5jb20uY24ifQ.LlQnK40vvqUSxNlCWQIL_pItJUI6U_2EfibrlMnTmZw`
// 引用API文件
axios.interceptors.response.use(response => {
  return response.data
}, error => {
  let errInfo = {}
  if (error.response != null) {
    errInfo = {
      data: error.response.data == null ? null : error.response.data,
      errCode: error.response.status
    }
    if (errInfo.errCode === 403) {
    }
  }
  return Promise.reject(errInfo)
})
// import api from '@/api/axios.js'
// import api from '@/api/restfulAPI.js'
// 将API方法绑定到全局
// Vue.prototype.$api = api
Vue.prototype.$axios = axios
Vue.use(ElementUI, { locale })
useI18n(Vue)
// ----------vueBeauty按需引入---------------
// Vue.use(dropdown)
// Vue.use(pagination)
// Vue.use(datePicker)
// Vue.use(form)
// Vue.use(timePicker)
// Vue.use(tree)
// Vue.use(badge)
// Vue.use(select)
// Vue.use(button)
//--------------------------------


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>'
})

