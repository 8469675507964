const zh = {
	messagePush: {
		loginFirst: '请先登录',
		cantEmpty: 'Http Method不能为空',
		nodata: '暂无数据',
		phoneNumber: '电话号码',
		index: '序号',
		atAll: '是否@所有人',
		atTitle: '@成员列表',
		cantEditTwo: '不能同时操作两条数据',
		addCantEdit: '添加与编辑成员不能同时操作',
		valueErr: 'Value值中有错误的json字符串',
		jsonErr: 'Json字符串格式错误',
		beforeSave: '有数据未保存，请先保存数据',
		sendSuccess: '发送成功',
		createSuccess: '创建成功',
		upDateSuccess: '更新成功',
		deleteSuccess: '删除成功',
		saveSuccess: '保存成功',
		webHookHeadConfig: 'WebHook HeaderConfig',
		webHookBodyConfig: 'WebHook BodyConfig',
		EditHeader: '编辑WebHook HeaderConfig',
		EditBody: '编辑WebHook BodyConfig',
		changeMethod: '切换提交方式',
		keyValue: '键值对',
		json: 'JSON',
		messagePush: '消息推送',
		groupList: '群组列表',
		searcheGroupName: '搜索群组名',
		groupName: '群组名',
		groupNumber: '群组人数',
		email: '邮件',
		enterpriseWechat: '企业微信',
		mpWechat: '微信公众号',
		action: '操作',
		addMethod: '添加方式',
		providers: '供应商',
		pleaseSelect: '请选择',
		senderEmail: '发送者邮箱',
		senderPassword: '发送者密码',
		emailSubject: '邮件主题',
		STMPServer: 'SMTP服务器',
		STMPPort: 'SMTP端口',
		secure: '加密',
		method: '方法',
		STMPAccount: 'SMTP账户',
		STMPPassword: 'SMTP密码',
		delete: '删除',
		deleteTip: '您确定要删除该群组吗？',
		builtIn: '自定义',
		userdefined: '自定义',
		back: '返回',
		groupMember: '群组成员',
		pleaseEnterEmail: '请输入新建成员邮箱号',
		sendMethod: '发送方式',
		mainsend: '主送',
		secretsend: '密送',
		carbonCopy: '抄送',
		ethodcopy: '抄送方式',
		deleteNumberTip: '您确定要删除该群组成员吗？',
		firstName: '名',
		lastName: '姓',
		dingTalk: '钉钉',
		pleaseInput: '请输入'
	},
	apiTip: {
		deleteSuccess: '删除成功',
		deleteFailed: '删除失败',
		cancel: '取消',
		save: '保存',
		delete: '删除',
		ok: '确定',
		close: '关闭'
	}
}

export default zh