export const TOKEN_STG_KEY = 'apmToken'

export function rememberToken (token) {
  localStorage.setItem(TOKEN_STG_KEY, token)
}



export function setToken (token) {
  sessionStorage.setItem(TOKEN_STG_KEY, token)
}

export function setCookie (name, value, seconds) {
  seconds = seconds || 0
  var expires = ''
  if (seconds !== 0) {
    var date = new Date()
    date.setTime(date.getTime() + (seconds * 1000))
    expires = '; expires=' + date.toGMTString()
  }
  document.cookie = name + '=' + value + expires + '; path=/'
}

export function clearToken () {
  setCookie('refreshToken', '', -1)
}

export function getCookie (cname) {
  var name = cname + '='
  if (!!document.cookie === false) {
    return
  }
  var ca = document.cookie.split(';')
  console.log('ca', ca)
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1)
    if (c.indexOf(name) !== -1) return decodeURI(c.substring(name.length, c.length))
  }
  return ''
}

// 删除Cookie



