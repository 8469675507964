const en = {
	messagePush: {
		loginFirst: 'Please login first',
		cantEmpty: 'Http Method Cannot be empty',
		nodata: 'No data',
		phoneNumber: 'Phone Number',
		index: 'Index',
		atAll: '@All',
		atTitle: '@Member List',
		cantEditTwo: 'Can not operate two pieces of data at the same time',
		addCantEdit: 'Adding and editing members cannot be operated at the same time',
		valueErr: 'Wrong json string in value',
		jsonErr: 'Json format error',
		beforeSave: 'There is data not saved, please save the data first',
		sendSuccess: 'succeed to send',
		createSuccess: 'succeed to created',
		upDateSuccess: 'succeed to update',
		deleteSuccess: 'succeed to delete',
		saveSuccess: 'succeed to save',
		webHookHeadConfig: 'WebHook Header Config',
		webHookBodyConfig: 'WebHook Body Config',
		EditHeader: 'Edit WebHook Header Config',
		EditBody: 'Edit WebHook Body Config',
		changeMethod: 'Switch Method',
		keyValue: 'Key-Value',
		json: 'JSON',
		messagePush: 'Message Push',
		groupList: 'Group Lists',
		searcheGroupName: 'Search Name',
		groupName: 'Group Name',
		groupNumber: 'Group Number',
		email: 'Email',
		enterpriseWechat: 'Enterprise WeChat',
		mpWechat: 'WeChat official account',
		action: 'Action',
		addMethod: 'Add Methods',
		providers: 'Provider',
		pleaseSelect: 'Please',
		senderEmail: 'Sender E-mail',
		senderPassword: 'Sender Password',
		emailSubject: 'E-mail Subject',
		STMPServer: 'SMTP Server',
		STMPPort: 'SMTP Port',
		secure: 'Secure',
		method: 'Method',
		STMPAccount: 'SMTP Account',
		STMPPassword: 'SMTP Password',
		delete: 'Delete',
		deleteTip: 'Are you sure you want to delete the group?',
		builtIn: 'User Defined',
		userdefined: 'User Defined',
		sendMethod: 'Send Methods',
		mainsend: 'Main Send',
		back: 'Back',
		groupMember: 'Group Member',
		pleaseEnterEmail: 'Please enter the new member eamil',
		secretsend: 'Secret Send',
		carbonCopy: 'Carbon Copy',
		ethodcopy: 'Method of copy delivery',
		deleteNumberTip: 'Are you sure you want to delete the members of the group?',
		firstName: 'First Name',
		lastName: 'Last Name',
		dingTalk: 'DingTalk',
		pleaseInput: 'Please input'
	},
	apiTip: {
		deleteSuccess: 'Delete success!',
		deleteFailed: 'Delete failed!',
		cancel: 'CANCEL',
		save: 'SAVE',
		delete: 'DELETE',
		ok: 'OK',
		close: 'CLOSE'
	}
}

export default en
