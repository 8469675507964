<template>
  <div style="height: 100%;">
    <div v-if="!isGroupNumber" class="cache-box">
      <div class="group_title">
        <div style="display: flex;">
          <div style="padding-right: 15px;">{{ $t('messagePush.groupList') }}</div>
          <img src="../../../assets/icons/btn_main_search_normal_blue.svg" @click="searchInputClick"
            class="img_portal_url search_normal_img" v-show="!serachInputShowFlag">
          <el-input v-show="serachInputShowFlag" v-model="searchServiceValue" class="serach_service_value"
            :placeholder="$t('messagePush.searcheGroupName')" @input="serachFilterService" @blur="searchBlur"
            ref="searchInputRef" />
        </div>
        <img src="../../../assets/Microserviceicon/addServiceMesh_btn.svg" alt="" @click="addGroup" v-show="addButtonFlag"
          class="createFramework">
      </div>
      <div class="group_content">
        <el-table :data="groupData" :header-cell-style="homeHeaderCellSet" :row-style="tableRowClassName"
          class="group_table" :row-key='getRowKeys' :expand-row-keys="expands" @expand-change="expandChange"
          ref="msfhomeTableRef">
          <el-table-column prop="groupName" :label="$t('messagePush.groupName')">
            <template slot-scope="scope">
              <div v-if="scope.row.isConfig === 'read'" @click="clickGoMember(scope.row)" style="color: #409EF">
                <a class="name_link" @click="clickGoMember(scope.row)">&nbsp;<span
                    style="font-weight: 600;border-bottom: 1px solid #1291d6">{{ scope.row.groupName }}</span></a>
              </div>
              <div v-else class="group_name_template">
                <el-input v-model="scope.row.groupName" :placeholder="$t('messagePush.groupName')" ref="groupNameRef"
                  v-if="!groupNameFlag"></el-input>
                <div class="group_name_input" v-else>
                  <el-input v-model="scope.row.groupName" :placeholder="$t('messagePush.groupName')"
                    ref="red_groupNameRef"></el-input>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="groupNum" :label="$t('messagePush.groupNumber')">
          </el-table-column>
          <el-table-column :label="$t('messagePush.email')">
            <template slot-scope="scope">
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" v-show="scope.row.groupType === 'email'">
            </template>
          </el-table-column>
          <el-table-column :label="$t('messagePush.enterpriseWechat')">
            <template slot-scope="scope">
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                v-show="scope.row.groupType === 'enterpriseWechat'">
            </template>
          </el-table-column>
          <!-- <el-table-column :label="$t('messagePush.mpWechat')">
            <template slot-scope="scope">
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" v-show="scope.row.groupType === 'mpWechat'">
            </template>
          </el-table-column> -->
          <el-table-column :label="$t('messagePush.dingTalk')">
            <template slot-scope="scope">
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" v-show="scope.row.groupType === 'ding'">
            </template>
          </el-table-column>
          <el-table-column :label="'WebHook'">
            <template slot-scope="scope">
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" v-show="scope.row.groupType === 'WebHook'">
            </template>
          </el-table-column>
          <el-table-column :label="$t('messagePush.action')" width="150">
            <template slot-scope="scope">
              <div v-if="scope.row.isConfig === 'read'">
                <img src="../../../assets/icons/frame/icon-32-member-n.svg" alt="" @click="clickGoMember(scope.row)"
                  class="member_Icon">
                <img src="../../../assets/icons/sent_normal.png" alt="" class="sent_Icon" @click="sendTest(scope.row)">
                <img src="../../../assets/icons/btn_main_delete_normal_blue.svg" alt="" @click="clickDeleteGroup(scope.row)"
                  class="delete_Icon">
              </div>
            </template>
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-row type="flex" class="row_group_methods">
                <el-col :span="2" class="group_methods_title">
                  <div>{{ $t('messagePush.addMethod') }}</div>
                </el-col>
                <el-col :span="22" class="group_methods_content">
                  <el-row class="col_content" type="flex" align="middle"
                    :style="{ 'background-color': props.row.groupExpandType === 'email' ? '#f3f7fa' : '#f5f5f5' }">
                    <el-col :span="4" class="col3_content">
                      <el-radio v-model="props.row.groupExpandType" label="email"> {{ $t('messagePush.email') }}
                      </el-radio>
                    </el-col>
                    <el-col :span="20" class="col21_content">
                      <el-row>
                        <div v-if="props.row.isBuildIn" style="display: flex;align-items: center;">
                          <img src="../../../assets/icons/btn_main_switchoff_normal.svg" alt="" class="switch_in_out"
                            @click="toggleEmailMethods(props.row, false)">{{ $t('messagePush.builtIn') }}
                        </div>
                        <div v-else style="display: flex;align-items: center;">
                          <img src="../../../assets/icons/btn_main_switchon_normal.svg" alt="" class="switch_in_out"
                            @click="toggleEmailMethods(props.row, true)">{{ $t('messagePush.userdefined') }}
                        </div>
                      </el-row>
                      <el-row class="col21_row_content" v-show="props.row.isBuildIn">
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.providers') }}<span style="color: #f00">
                              *</span></div>
                          <el-select v-model="props.row.emailInMethod.provider"
                            :placeholder="$t('messagePush.pleaseSelect')"
                            :disabled="props.row.groupExpandType !== 'email'"
                            @change="selectProviderItem($event, props.row)" style="width: 95%">
                            <el-option v-for="item in providerOptions" :key="item.channelId" :label="item.channelName"
                              :value="item.channelName">
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.senderEmail') }}<span style="color: #f00">
                              *</span></div>
                          <el-input v-model="props.row.emailInMethod.senderEmail"
                            :placeholder="$t('messagePush.senderEmail')"
                            :disabled="props.row.groupExpandType !== 'email'"
                            :title="props.row.emailInMethod.senderEmail" style="width: 95%;"></el-input>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.senderPassword') }}<span
                              style="color: #f00">
                              *</span></div>
                          <el-input v-model="props.row.emailInMethod.senderPassword" type="password" show-password
                            :placeholder="$t('messagePush.senderPassword')"
                            :disabled="props.row.groupExpandType !== 'email'" style="width: 95%;"></el-input>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.emailSubject') }}</div>
                          <el-input v-model="props.row.emailInMethod.senderSubject"
                            :placeholder="$t('messagePush.emailSubject')"
                            :disabled="props.row.groupExpandType !== 'email'" style="width: 95%;"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <div class="add_save_cancel add_edit_img" v-if="props.row.groupExpandType === 'email'">
                            <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                              @click="saveEditgroup(props.row)">
                            <img src="../../../assets/Microserviceicon/cancel.svg" alt=""
                              @click="canclEditgroup(props.row, 'in_eamil')">
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="col21_row_content" v-show="!props.row.isBuildIn">
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.STMPServer') }}<span style="color: #f00">
                              *</span></div>
                          <el-input v-model="props.row.emailUserMethod.STMPServer"
                            :placeholder="$t('messagePush.STMPServer')"
                            :disabled="props.row.groupExpandType !== 'email'" style="width: 95%"></el-input>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.STMPPort') }}<span style="color: #f00">
                              *</span></div>
                          <el-input v-model="props.row.emailUserMethod.STMPPort"
                            :placeholder="$t('messagePush.STMPPort')" :disabled="props.row.groupExpandType !== 'email'"
                            style="width: 95%"></el-input>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.secure') }}<span style="color: #f00">
                              *</span>
                          </div>
                          <el-select v-model="props.row.emailUserMethod.secure"
                            :placeholder="$t('messagePush.pleaseSelect')"
                            :disabled="props.row.groupExpandType !== 'email'" @change="changeSecure($event, props.row)"
                            style="width: 95%">
                            <el-option label="true" value="true"></el-option>
                            <el-option label="false" value="false"></el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.method') }}<span style="color: #f00">
                              *</span>
                          </div>
                          <el-select v-model="props.row.emailUserMethod.method"
                            :placeholder="$t('messagePush.pleaseSelect')"
                            :disabled="props.row.groupExpandType !== 'email' || (props.row.groupExpandType === 'email' && props.row.emailUserMethod.secure == 'false')"
                            style="width: 95%">
                            <el-option label="TLS" value="TLS"></el-option>
                            <el-option label="SSL" value="SSL"></el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                      <el-row class="col21_row_content col21_user_content" v-show="!props.row.isBuildIn">
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.STMPAccount') }}<span style="color: #f00">
                              *</span></div>
                          <el-tooltip
                            :content="props.row.emailUserMethod.STMPAccount ? props.row.emailUserMethod.STMPAccount : $t('messagePush.STMPAccount')"
                            placement="bottom" effect="light">
                            <el-input v-model="props.row.emailUserMethod.STMPAccount"
                              :placeholder="$t('messagePush.STMPAccount')"
                              :disabled="props.row.groupExpandType !== 'email'" style="width: 95%"></el-input>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.STMPPassword') }}<span style="color: #f00">
                              *</span></div>
                          <el-input v-model="props.row.emailUserMethod.STMPPassword"
                            :placeholder="$t('messagePush.STMPPassword')"
                            :disabled="props.row.groupExpandType !== 'email'" style="width: 95%"></el-input>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.senderEmail') }}<span style="color: #f00">
                              *</span></div>
                          <el-tooltip
                            :content="props.row.emailUserMethod.senderEmail ? props.row.emailUserMethod.senderEmail : $t('messagePush.senderEmail')"
                            placement="bottom" effect="light">
                            <el-input v-model="props.row.emailUserMethod.senderEmail"
                              :placeholder="$t('messagePush.senderEmail')"
                              :disabled="props.row.groupExpandType !== 'email'" style="width: 95%"></el-input>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="5">
                          <div style="font-weight: 700;">{{ $t('messagePush.emailSubject') }}</div>
                          <el-input v-model="props.row.emailUserMethod.emailSubject"
                            :placeholder="$t('messagePush.emailSubject')"
                            :disabled="props.row.groupExpandType !== 'email'" style="width: 95%"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <div class="add_save_cancel add_edit_img" v-if="props.row.groupExpandType === 'email'">
                            <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                              @click="saveEditgroup(props.row)">
                            <img src="../../../assets/Microserviceicon/cancel.svg" alt=""
                              @click="canclEditgroup(props.row, 'user_eamil')">
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row class="col_content" type="flex" align="middle"
                    :style="{ 'background-color': props.row.groupExpandType === 'enterpriseWechat' ? '#f3f7fa' : '#f5f5f5' }">
                    <el-col :span="4" class="col3_content" style="padding: 12px 0;">
                      <el-radio v-model="props.row.groupExpandType" label="enterpriseWechat">
                        {{ $t('messagePush.enterpriseWechat') }}
                        <el-popover @show="hoverWepy" @hide="hideHoverWepy" placement="right" trigger="hover">
                          <div class="popo_content_img">
                            <div class="popover_wepy" style="margin-bottom: 8px;">
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">①</div>
                                <img src="../../../assets/icons/frame/wepy_01.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;margin: 0 8px;">
                                <div class="wepy_order_num">②</div>
                                <img src="../../../assets/icons/frame/wepy_02.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">③</div>
                                <img src="../../../assets/icons/frame/wepy_03.png" alt="">
                              </div>
                            </div>
                            <div class="popover_wepy">
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">④</div>
                                <img src="../../../assets/icons/frame/wepy_04.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">⑤</div>
                                <img src="../../../assets/icons/frame/wepy_05.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 6px;">
                                <div class="wepy_order_num">⑥</div>
                                <img src="../../../assets/icons/frame/wepy_06.png" alt="">
                              </div>
                            </div>
                          </div>
                          <img slot="reference" src="../../../assets/Microserviceicon/questions_normal.svg" alt=""
                            class="questionVersion" ref="pointoutRef">
                        </el-popover>
                      </el-radio>
                    </el-col>
                    <el-col :span="20" class="col21_content">
                      <el-row>
                        <el-col :span="24">
                          <div v-if="props.row.groupExpandType === 'enterpriseWechat'"
                            class="add_save_cancel add_save_cancel_wepy add_edit_img">
                            <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                              @click="saveEditgroup(props.row)">
                            <img src="../../../assets/Microserviceicon/cancel.svg" alt=""
                              @click="canclEditgroup(props.row, 'enterpriseWechat')">
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <!-- <el-row class="col_content" type="flex" align="middle" style="background-color: #f5f5f5;"
                    :style="{ 'background-color': props.row.groupExpandType === 'mpWechat' ? '#f3f7fa' : '#f5f5f5' }">
                    <el-col :span="4" class="col3_content">
                      <el-radio v-model="props.row.groupExpandType" label="mpWechat">{{ $t('messagePush.mpWechat') }}
                      </el-radio>
                    </el-col>
                    <el-col :span="20" class="col21_content">
                      <el-row>
                        <el-col :span="5">
                          <el-input v-model="props.row.publicMethods.appid" placeholder="Appid"
                            :disabled="props.row.groupExpandType !== 'mpWechat'" style="width: 95%"></el-input>
                        </el-col>
                        <el-col :span="5">
                          <el-input v-model="props.row.publicMethods.appsecret" placeholder="Appsecret"
                            :disabled="props.row.groupExpandType !== 'mpWechat'" style="width: 95%"></el-input>
                        </el-col>
                        <el-col :span="14">
                          <div class="add_save_cancel add_edit_img" v-if="props.row.groupExpandType === 'mpWechat'">
                            <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                              @click="saveEditgroup(props.row)">
                            <img src="../../../assets/Microserviceicon/cancel.svg" alt=""
                              @click="canclEditgroup(props.row, 'mpWechat')">
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row> -->
                  <el-row class="col_content" type="flex" align="middle"
                    :style="{ 'background-color': props.row.groupExpandType === 'ding' ? '#f3f7fa' : '#f5f5f5' }">
                    <el-col :span="4" class="col3_content" style="padding: 12px 0;">
                      <el-radio v-model="props.row.groupExpandType" label="ding">
                        {{ $t('messagePush.dingTalk') }}
                        <el-popover @show="hoverDing" @hide="hideHoverDing" placement="right" trigger="hover">
                          <div class="popo_content_img">
                            <div class="popover_ding" style="margin-bottom: 8px;">
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">①</div>
                                <img src="../../../assets/icons/dingding/1.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;margin: 0 8px;">
                                <div class="wepy_order_num">②</div>
                                <img src="../../../assets/icons/dingding/2.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">③</div>
                                <img src="../../../assets/icons/dingding/3.png" alt="">
                              </div>
                            </div>
                            <div class="popover_ding">
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">④</div>
                                <img src="../../../assets/icons/dingding/4.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">⑤</div>
                                <img src="../../../assets/icons/dingding/5.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 6px;">
                                <div class="wepy_order_num">⑥</div>
                                <img src="../../../assets/icons/dingding/6.png" alt="">
                              </div>
                            </div>
                            <div class="popover_ding" style="margin-bottom: 8px;">
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">⑦</div>
                                <img src="../../../assets/icons/dingding/7.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;margin: 0 8px;">
                                <div class="wepy_order_num">⑧</div>
                                <img src="../../../assets/icons/dingding/8.png" alt="">
                              </div>
                              <div style="border: 1px solid #ccc;border-radius: 5px;">
                                <div class="wepy_order_num">⑨</div>
                                <img src="../../../assets/icons/dingding/9.png" alt="">
                              </div>
                            </div>
                          </div>
                          <img slot="reference" src="../../../assets/Microserviceicon/questions_normal.svg" alt=""
                            class="questionVersion" ref="pointDingRef">
                        </el-popover>
                      </el-radio>
                    </el-col>
                    <el-col :span="20" class="col21_content">
                      <el-row>
                        <el-col :span="24">
                          <div v-if="props.row.groupExpandType === 'ding'"
                            class="add_save_cancel add_save_cancel_wepy add_edit_img">
                            <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                              @click="saveEditgroup(props.row)">
                            <img src="../../../assets/Microserviceicon/cancel.svg" alt=""
                              @click="canclEditgroup(props.row, 'ding')">
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row class="col_content" type="flex" align="middle" style="background-color: #f5f5f5;"
                    :style="{ 'background-color': props.row.groupExpandType === 'WebHook' ? '#f3f7fa' : '#f5f5f5' }">
                    <el-col :span="4" class="col3_content">
                      <el-radio v-model="props.row.groupExpandType" label="WebHook"> WebHook</el-radio>
                    </el-col>
                    <el-col :span="20" class="col21_content">
                      <el-row>
                        <el-col :span="5">
                          <!-- <el-select v-model="props.row.emailUserMethod.method" placeholder="Method"
                            :disabled="props.row.groupExpandType !== 'WebHook'" style="width: 95%">
                            <el-option label="GET" value="GET"></el-option>
                            <el-option label="POST" value="POST"></el-option>
                            <el-option label="PUT" value="PUT"></el-option>
                          </el-select> -->
                        </el-col>
                        <el-col :span="14">
                          <div class="add_save_cancel add_edit_img" v-if="props.row.groupExpandType === 'WebHook'">
                            <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt=""
                              @click="saveEditgroup(props.row)">
                            <img src="../../../assets/Microserviceicon/cancel.svg" alt=""
                              @click="canclEditgroup(props.row, 'WebHook')">
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <template slot="empty">
            <div v-if="noDataFlag">
              <img class="service-checking-icon" />
              <div>loading...</div>
            </div>
            <div v-else>
              {{ $t('messagePush.nodata') }}
            </div>
          </template>
        </el-table>
        <el-pagination :current-page.sync="groupCurrentPage" :page-size="limit" :page-sizes="[10, 20, 30, 50]"
          layout="total, sizes, prev, pager, next" :total="totalCount" @size-change="handleGroupSizeChange"
          @current-change="handleGrpupCurrentChange">
        </el-pagination>
      </div>
    </div>
    <div v-else class="cache-box">
      <member-list :isNeedMember="isNeedMember" :pushUrl="preUrl" @cancel="canclAdd" @save="saveAdd">
      </member-list>
    </div>
    <!-- 删除 -->
    <el-dialog :visible.sync="deletegroupVisible" :width="`500px`" :show-close="false" :close-on-click-modal="false"
      style="text-align: left;" :append-to-body="true" class="delete_secret_item">
      <div slot="title" class="dialog-title">
        <span style="font-size: 20px;font-weight: 700;">{{ $t('messagePush.delete') }}</span>
      </div>
      <span class="deleteContentNote">{{ $t('messagePush.deleteTip') }}</span>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex;justify-content: flex-end;">
          <div class="global_button global_cancel_normal" @click="deletegroupVisible = false">{{ $t('apiTip.cancel') }}
          </div>
          <div class="global_button global_delete_normal" @click="confirmDeleteGroupItem">{{ $t('apiTip.delete') }}
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import * as tokenAPI from '../../../js/tokenProcess'
import { LANG_EN, LANG_ZHCN, LANG_ZHTW } from '../../../js/i18n/lang'
import axios from 'axios'
import MemberList from './MessagePushMember.vue'
import { Message } from 'element-ui';

export default {
  name: '',
  data() {
    return {
      deletegroupVisible: false,
      addButtonFlag: true,
      serachInputShowFlag: false,
      searchServiceValue: '',
      groupData: [],
      noDataFlag: true,
      groupCurrentPage: 1,
      limit: 10,
      offset: 0,
      providerOptions: [],
      expands: [],
      isAddGroupName: '',
      isGroupNumber: false,
      licenseurl: '',
      totalCount: 0,
      isDeleteGroupId: null,
      isNeedMember: {},
      timerId: null,
      preUrl: window.location.origin,
      // preUrl: 'https://portal-msf-system-cluster01.msf-dev.wise-paas.top',
      groupNameFlag: false,
      eltoken: '',
      messageUrl: '/api/v1.5',
      cluster: ''
    }
  },
  components: {
    MemberList
  },
  watch: {
    // licenseurl: {
    //   handler(newVal) {
    //     console.log(newVal);
    //     switch (newVal) {
    //       case 'tw': this.$i18n.locale = LANG_ZHTW; break;
    //       case 'zh': this.$i18n.locale = LANG_ZHCN; break;
    //       default: this.$i18n.locale = LANG_EN; break;
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // }
  },
  created() {
    //this.cluster=this.$store.state.currentFrameName
    this.getGroupList(10, 0, '');
    this.getProviderOption();
    const middleorgin = window.location.host
    if (middleorgin.indexOf('portal-msf') !== -1) {
        this.licenseurl = tokenAPI.getCookie('license_url')
        this.licenseurl =='tw'? this.$i18n.locale = LANG_ZHTW: this.licenseurl =='zh' ? this.$i18n.locale = LANG_ZHCN : this.$i18n.locale = LANG_EN
    } else if (middleorgin.indexOf('portal-apm') !== -1) {
        this.licenseurl = tokenAPI.getCookie('sclang')
        this.licenseurl =='tw'? this.$i18n.locale = LANG_ZHTW: this.licenseurl =='zh' ? this.$i18n.locale = LANG_ZHCN : this.$i18n.locale = LANG_EN
    }
  },
  mounted() {
    console.log('window', window.location.search)
    const middlesearch = window.location.search
    if (middlesearch.length !== 0) {
      if (middlesearch.indexOf('zh') !== -1) {
        this.$i18n.locale = LANG_ZHCN;
      } else if (middlesearch.indexOf('tw') !== -1) {
        this.$i18n.locale = LANG_ZHTW;
      } else if (middlesearch.indexOf('en') !== -1) {
        this.$i18n.locale = LANG_EN;
      }
    }
  },
  methods: {
    getGroupList(limit, offset, val) {
      this.addButtonFlag = true;
      this.groupData = [];
      this.noDataFlag = true;
      let url = `${this.messageUrl}/Groups?limit=${limit}&offset=${offset}&name=${val}&cluster=${this.cluster}`;
      axios.get(url).then(res => {
        console.log('res', res)
        let data = res.list
        this.totalCount = res.totalCount;
        let i = 1;
        console.log('data', data, res, res)
        if (!!data) {
          data.map(item => {
            this.groupData.push(
              {
                id: i++,
                groupId: item.groupId,
                groupName: item.name,
                groupNum: item.member,
                groupType: item.type, //email enterpriseWechat
                groupExpandType: item.type,
                isBuildIn: item.isBuildIn, //true false
                isConfig: 'read',
                emailUserMethod: { //自定义
                  STMPServer: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.host,
                  STMPPort: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.port, //number
                  secure: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.secure.toString(), //布尔
                  method: (item.type === 'email' && item.isBuildIn) || (item.type !== 'email') ? '' : item.config.method,
                  STMPAccount: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.username,
                  STMPPassword: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.password,
                  senderEmail: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.senderEmail,
                  emailSubject: (item.type === 'email' && item.isBuildIn) || item.type !== 'email' ? '' : item.config.emailSubject
                },
                emailInMethod: {
                  provider: (item.type === 'email' && !item.isBuildIn) || item.type !== 'email' ? '' : item.channelName,
                  senderEmail: (item.type === 'email' && !item.isBuildIn) || item.type !== 'email' ? '' : item.config.senderEmail,
                  senderPassword: (item.type === 'email' && !item.isBuildIn) || item.type !== 'email' ? '' : item.config.password,
                  senderSubject: (item.type === 'email' && !item.isBuildIn) || item.type !== 'email' ? '' : item.config.emailSubject
                },
                publicMethods: {
                  appid: item.config.appid,
                  appsecret: item.config.appsecret
                },
                config: {
                  method: ''
                }
              }
            )
          })
        }

        this.noDataFlag = false;
      }).catch(error => {
        Message({
          message: this.$t('messagePush.loginFirst'),
          type: 'error',
          duration: 6000,
          showClose: true
        });
        this.noDataFlag = false;
      })
    },
    getProviderOption() {
      let url = `${this.messageUrl}/Group/channel/getchannels?cluster=${this.cluster}`;
      axios.get(url).then(res => {
        this.providerOptions = res.data;
        console.log('providerOptions', this.providerOptions)
      }).catch(error => {
        console.log(error);
      })
    },
    createGroup(params, row) {
      let url = `${this.messageUrl}/Groups?cluster=${this.cluster}`;
      axios.post(url, params).then(res => {
        this.getGroupList(this.limit, this.offset, this.searchServiceValue);
        Message({
          message: this.$t('messagePush.createSuccess'),
          type: 'success'
        });
        this.canclEditgroup(row);
      }).catch(error => {
        console.log(error);
        Message({
          message: error.response.data.errMsg,
          type: 'error'
        });
        this.canclEditgroup(row);
      })
    },
    updateGroup(id, row) {
      let url = `${this.messageUrl}/Groups/${id}?cluster=${this.cluster}`;
      axios.put(url, row).then(res => {
        this.getGroupList(this.limit, this.offset, this.searchServiceValue);
        Message({
          message: this.$t('messagePush.upDateSuccess'),
          type: 'success'
        });
      }).catch(error => {
        console.log(error);
        this.getGroupList(this.limit, this.offset, this.searchServiceValue);
        Message({
          message: error.response.data.errMsg,
          type: 'error'
        });
      })
    },
    deleteGroup(id) {
      let url = `${this.messageUrl}/Groups?cluster=${this.cluster}`
      const params = {
        group_id_list: id
      }
      axios.delete(url, { data: params }, { withCredentials: true }).then(res => {
        this.getGroupList(this.limit, this.offset, this.searchServiceValue);
        Message({
          message: this.$t('messagePush.deleteSuccess'),
          type: 'success'
        });
        this.deletegroupVisible = false;
      }).catch(error => {
        console.log(error);
        Message({
          message: error.data.errMsg,
          type: 'error'
        });
        this.deletegroupVisible = false;
      })
    },
    serachFilterService(val) {
      this.debounce();
      this.searchServiceValue = val;
      this.timerId = setTimeout(() => {
        this.getGroupList(this.limit, 0, val);
      }, 500);
    },
    // 防抖
    debounce() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
    },
    // 自动获取焦点
    searchInputClick() {
      this.serachInputShowFlag = true;
      this.$nextTick(() => {
        this.$refs.searchInputRef.focus();
      })
    },
    searchBlur(e) {
      if (e.target.value) {
        this.serachInputShowFlag = true;
      } else {
        this.serachInputShowFlag = false;
      }
    },
    homeHeaderCellSet({ row, column, rowIndex, columnIndex }) {
      return 'color: #006296;font-weight: 700;font-size:12px;border-color:#006296'
    },
    tableRowClassName({ row, rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'background-color: #efefef'
      } else {
        return 'background-color: #fafafa'
      }
    },
    handleGroupSizeChange(val) {
      this.limit = val;
      this.groupCurrentPage = 1
      this.getGroupList(this.limit, 0, this.searchServiceValue);
    },
    handleGrpupCurrentChange(val) {
      this.offset = this.limit * (val - 1);
      this.groupCurrentPage = val;
      this.getGroupList(this.limit, this.offset, this.searchServiceValue);
    },
    addGroup() {
      this.addButtonFlag = false;
      this.expands = [];
      this.groupData.push({
        id: this.groupData.length + 1,
        groupName: '',
        isBuildIn: true,
        isConfig: 'write',
        groupExpandType: 'email',
        emailUserMethod: {
          STMPServer: '',
          STMPPort: null,
          secure: 'false',
          method: '',
          STMPAccount: '',
          STMPPassword: '',
          senderEmail: '',
          emailSubject: ''
        },
        emailInMethod: {
          provider: '',
          senderEmail: '',
          senderPassword: '',
          senderSubject: ''
        },
        publicMethods: {
          appid: '',
          appsecret: ''
        },
        config: {
          method: ''
        }
      })
      this.expands.push(this.groupData.length);
      this.$nextTick(() => {
        if (this.$refs.groupNameRef) {
          this.$refs.groupNameRef.focus();
        }
      })
    },
    expandChange(row, expandedRows) {
      if (expandedRows.length) {//此时展开
        this.expands = [];
        if (row) {
          this.expands.push(row.id);
        }
      } else {//折叠
        this.expands = [];
      }
    },
    getRowKeys(row) {//行数据的key，用于优化table的渲染
      return row.id;
    },
    sendTest(row) {
      console.log('row', row)
      let url = `${this.messageUrl}/Groups/send?cluster=${this.cluster}`;
      let data = [{
        groupId: row.groupId,
        message: 'This is a test message'
      }]
      axios.post(url, data).then((res) => {
        console.log('res', res)
        Message({
          message: this.$t('messagePush.sendSuccess'),
          type: 'success',
          showClose: false
        })
      }).catch((err) => {
        console.log('err', err.data.error.message)
        Message({
          message: err.data.error.message,
          type: 'error',
          showClose: false
        })
      })
    },
    toggleEmailMethods(row, item) {
      if (row.groupExpandType === 'email') {
        row.isBuildIn = item
      }
    },
    canclAdd() {
      this.isGroupNumber = false;
      this.getGroupList(this.limit, this.offset, this.searchServiceValue);
    },
    saveAdd() {
      this.isGroupNumber = false;
    },
    saveEditgroup(row, name) {
      if (row.isConfig === 'write') { //添加
        if (row.groupName) {
          this.groupNameFlag = false;
          let body = this.pinParams(row);
          this.createGroup(body, row);
        } else {
          this.groupNameFlag = true;
          this.$nextTick(() => {
            this.$refs.red_groupNameRef.focus();
          })
        }
      } else { //编辑
        let editBody = this.pinParams(row);
        this.updateGroup(row.groupId, editBody);
      }

    },
    selectProviderItem(val, row) {
    },
    pinParams(row) {
      // 如果是内置host,port,secure,method,username从provider接口获取到
      // 如果是自定义host,port,secure,method,username双向数据绑定
      let host = ''
      let port = ''
      let secure = ''
      let method = ''
      let username = ''
      let password = ''
      console.log('isBuildIn', row.isBuildIn, this.providerOptions, row.emailInMethod.provider)
      if (row.isBuildIn) {
        this.providerOptions.map(item => {
          if (item.channelName === row.emailInMethod.provider) {
            host = item.host;
            port = item.port;
            secure = item.secure;
            method = item.method;
            username = item.username;
          }
        })
      } else {
        host = row.emailUserMethod.STMPServer;
        port = parseInt(row.emailUserMethod.STMPPort);
        secure = row.emailUserMethod.secure == 'true' ? true : false;
        method = row.emailUserMethod.method;
        username = row.emailUserMethod.STMPAccount;
      }
      let body = {
        name: row.groupName,
        type: row.groupExpandType,
        isBuildIn: row.isBuildIn,
        channelName: row.emailInMethod.provider,
        config: {
          host,
          port: port ? port : 0,
          secure: secure === 'true' || secure === true ? true : false,
          method: method || row.emailUserMethod.method,
          username,
          password: row.isBuildIn ? row.emailInMethod.senderPassword : row.emailUserMethod.STMPPassword,
          senderEmail: row.isBuildIn ? row.emailInMethod.senderEmail : row.emailUserMethod.senderEmail,
          emailSubject: row.isBuildIn ? row.emailInMethod.senderSubject : row.emailUserMethod.emailSubject,
          appid: row.publicMethods.appid,
          appsecret: row.publicMethods.appsecret
        }

      }
      return body
    },
    canclEditgroup(row, name) {
      if (row.isConfig === 'write') {
        this.groupData.map((item, index) => {
          if (row.id === item.id) {
            this.groupData.splice(index, 1)
          }
        })
        this.addButtonFlag = true
      } else {
        this.getGroupList(this.limit, this.offset, this.searchServiceValue);
      }
    },
    clickDeleteGroup(row) {
      this.deletegroupVisible = true;
      this.isDeleteGroupId = row.groupId;
    },
    confirmDeleteGroupItem() {
      this.deleteGroup([this.isDeleteGroupId])
    },
    clickGoMember(row) {
      this.isNeedMember = row;
      this.isGroupNumber = true;
    },
    hoverWepy() {
      this.$refs.pointoutRef.src = require('../../../assets/Microserviceicon/questions.svg')
    },
    hoverDing() {
      this.$refs.pointDingRef.src = require('../../../assets/Microserviceicon/questions.svg')
    },
    hideHoverWepy() {
      this.$refs.pointoutRef.src = require('../../../assets/Microserviceicon/questions_normal.svg')
    },
    hideHoverDing() {
      this.$refs.pointDingRef.src = require('../../../assets/Microserviceicon/questions_normal.svg')
    },
    changeSecure(val, row) {
      if (val == 'true') {
        row.emailUserMethod.method = 'TLS';
      }
    }
  }
}
</script>

<style>
.el-table th>.cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style scoped>
.name_link {
  color: #108ee9;
  cursor: pointer;
}

* {
  font-family: Roboto;
}
.detailHeader /deep/ .el-dialog__footer {
  padding: 0 0 0;
  border-top: 1px solid #c8c8c8;
}

.detailHeader /deep/ .el-dialog__body {
  padding: 10px 10px;
}

.detailHeader /deep/ .el-input__inner {
  padding: 0 5px;
}

.detailHeader /deep/ .el-table td {
  padding: 4px 0px;
  height: 32px;
}

.detailHeader /deep/ .el-table th {
  padding: 4px 0px;
}

.detailHeader /deep/ .el-dialog__header {
  padding: 19px 15px 10px;
  text-align: start;
  border-bottom: 1px solid #c8c8c8;
}
.global_button {
  width: 110px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto;
  color: #787878;
  text-align: center;
  cursor: pointer;
}

.global_delete_normal {
  color: #ce2f2f
}

.global_delete_normal:active {
  background-color: #ce2f2f;
  color: #fff
}

.global_cancel_normal:active {
  background-color: #787878;
  color: #fff
}

.cache-box {
  display: flex;
  width: 100%;
  min-width: 1080px;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
}

.group_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 32px;
  padding: 13px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #006296;
}

.group_content {
  flex-grow: 1;
  padding: 0 25px;
  margin: 0 32px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.img_portal_url {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.img_portal_url:hover {
  cursor: pointer;
  background-color: #E6E6E6;
}

.search_normal_img {
  vertical-align: middle;
}

.group_title /deep/ .el-input {
  width: 161px !important;
}

.serach_service_value>>>.el-input__inner {
  height: 32px;
}

.group_name_input /deep/ .el-input__inner:focus {
  border-color: red;
  outline: 0;
}

.group_content /deep/ .el-table td,
.el-table th {
  padding: 0;
  height: 48px;
  overflow: auto;
}

.group_content /deep/ .cell:hover {
  background-color: transparent;
}

.group_content /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: transparent;
}

.group_table {
  font-size: 14px;
}

.group_content /deep/ .el-pagination {
  height: 48px;
  padding: 10px 0 0 0;
  background-color: #fff;
}

.row_group_methods {
  padding: 14px 17px;
}

.group_methods_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  background-color: #f3f7fa;
  font-weight: bold;
  color: #006296;
}

.col_content {
  margin-bottom: 6px;
  background-color: #f5f5f5;
}

.col21_content {
  padding: 12px 0 12px 22px;
  border-left: 1px solid #ccc;
}

.group_table /deep/ .el-input__inner {
  height: 28px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.group_name_template /deep/ .el-input__inner {
  width: 98%;
}

.col3_content {
  margin-left: 10px;
}

.switch_in_out {
  height: 20px;
  cursor: pointer;
}

.col21_row_content {
  margin-top: 20px;
}

.col21_user_content {
  margin-top: 20px;
}

.popo_content_img::-webkit-scrollbar,
.cache-box::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}

.popo_content_img::-webkit-scrollbar-thumb,
.cache-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #C8C8C8;
}

.col21_row_content .el-col div:first-child {
  margin-bottom: 10px;
}

.add_save_cancel {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -12px !important;
}

.add_save_cancel_wepy {
  margin-bottom: -24px !important;
}

.delete_secret_item /deep/ .el-dialog__body {
  padding: 16px 20px;
}

/deep/ .el-input__suffix {
  height: 29px;
}

.delete_secret_item /deep/ .el-dialog__footer {
  padding: 0 20px;
  height: 49px;
  border-top: 1px solid #ccc;
}

.cancel_hover_show,
.delete_secret_show {
  height: 48px;
}

/deep/ .el-input__icon {
  line-height: 28px;
}

.cancel_hover_show:hover {
  cursor: pointer;
  height: 48px;
  content: url('../../../assets/Microserviceicon/btn-main-word-cancel-hover.svg');
}

.cancel_hover_show:active {
  height: 48px;
  content: url('../../../assets/Microserviceicon/btn-main-word-cancel-active.svg');
}

.delete_secret_show:hover {
  height: 48px;
  cursor: pointer;
  content: url('../../../assets/Microserviceicon/btn-main-word-delete-hover.svg');
}

.delete_secret_show:active {
  height: 48px;
  content: url('../../../assets/Microserviceicon/btn-main-word-delete-active.svg');
}

.questionVersion {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.popo_content_img {
  display: flex;
  flex-direction: column;
  height: 670px;
  overflow: auto;
}

.popover_wepy,
.popover_ding {
  display: flex;
  justify-content: space-between;
}

.popover_wepy img {
  width: 320px;
}

.popover_ding img {
  width: 400px;
}

.wepy_order_num {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  border-bottom: solid 1px #707070;
  background-color: #f3f7fa;
  font-size: 20px;
  color: #006296;
  font-weight: bold;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.service-checking-icon {
  width: 32px;
  height: 32px;
  vertical-align: bottom
}
.sent_Icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.sent_Icon:hover {
  content: url('../../../assets/icons/sent_hover.png');
}
.sent_Icon:active {
  content: url('../../../assets/icons/sent_press.png');
}
.service-checking-icon {
  content: url('../../../assets/icons/img_loading1.svg');
  transition: transform 1s;
  animation: rotation 1.3s linear infinite;
}

.createFramework,
.delete_Icon,
.member_Icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.createFramework:hover {
  width: 32px;
  height: 32px;
  content: url('../../../assets/icons/btn_main_create_hover.svg');
}

.createFramework:active {
  width: 32px;
  height: 32px;
  content: url('../../../assets/icons/btn_main_create_active_blue.svg');
}

.add_edit_img img {
  cursor: pointer;
}

.delete_Icon:hover {
  content: url('../../../assets/icons/btn_main_delete_hover.svg');
}

.delete_Icon:active {
  content: url('../../../assets/icons/btn_main_delete_active.svg');
}

.member_Icon:hover {
  content: url('../../../assets/icons/frame/icon-32-member-h.svg');
}

.member_Icon:active {
  content: url('../../../assets/icons/frame/icon-32-member-a.svg');
}
</style>