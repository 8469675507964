import VueI18n from 'vue-i18n'

import EnElementLang from 'element-ui/lib/locale/lang/en'
import ZhCNElementLang from 'element-ui/lib/locale/lang/zh-CN'
import ZhTWElementLang from 'element-ui/lib/locale/lang/zh-TW'
import ElementLocale from 'element-ui/lib/locale'

import EnLang from './en'
import ZhCNLang from './zh'
import ZHTWLang from './fan'

export const LANG_EN = 'en'
export const LANG_ZHCN = 'zh'
export const LANG_ZHTW = 'tw'
export let i18n

let curLocal = LANG_ZHCN

const messages = {
  [LANG_EN]: {
    ...EnLang,
    ...EnElementLang
  },
  [LANG_ZHCN]: {
    ...ZhCNLang,
    ...ZhCNElementLang
  },
  [LANG_ZHTW]: {
    ...ZHTWLang,
    ...ZhTWElementLang
  }
}

export function setLang (lang) {
  curLocal = lang
  i18n.locale = curLocal
  localStorage.setItem('scLang', curLocal)
}

export function getLang () {
  return curLocal
}

export function useI18n (Vue) {
  Vue.use(VueI18n)
  let lang=navigator.language||navigator.userLanguage
  console.log("lang",lang)
  if(lang ==='zh-CN'){
    console.log("lang",lang)
    curLocal=LANG_ZHCN
  }else if(lang==='zh-TW') {
    curLocal=LANG_ZHTW
  }else {
    curLocal=LANG_EN
  }
  i18n = new VueI18n({
    locale: `${curLocal}`,
    messages
  })

  ElementLocale.i18n((key, value) => i18n.t(key, value))
}

