import Vue from 'vue'
import Router from 'vue-router'
import MessagePush from '../components/views/message/MessagePush.vue'
import MessagePushMember from '../components/views/message/MessagePushMember.vue'
import * as tokenAPI from '../js/tokenProcess'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
const routes = [
  {
    path: '/',
    component: MessagePush
  },
  {
    path: '/msf/messagepushmember',
    component: MessagePushMember
  }
]

const router = new Router({
  routes
})

function routeInterupter (to, from, next) {
  console.log(`routeInterupter to:${to.name} from:${from.name} next:${next.name}`)
  let EIToken = tokenAPI.getCookie('EIToken')
  console.log('EIToken', EIToken)
  if (EIToken === undefined || EIToken === '') {
    if (to.name === 'login' ||
        to.name === 'ForgetSend' ||
        to.name === 'register' ||
        to.name === 'Forgetpass' ||
        to.name === null) {
      next()
    } else {
      console.log('user not login')
      next({name: 'login'})
    }
  } else {
    next()
  }
}

// router.beforeEach((to, from, next) => {
//   routeInterupter(to, from, next)
// })
export default router
