const fan = {
  messagePush: {
    loginFirst: '請先登錄',
    cantEmpty: 'HttpMethod不能為空',
    nodata: '暫無數據',
    phoneNumber: '電話號碼',
		index: '序號',
		atAll: '是否@所有人',
		atTitle: '@成員列表',
		cantEditTwo: '不能同時操作兩條數據',
		addCantEdit: '添加與編輯成員不能同時操作',
		valueErr: 'Value值中有錯誤的json字符串',
		jsonErr: 'Json字符串格式錯誤',
		beforeSave: '有數據未保存，請先保存數據',
    sendSuccess: '發送成功',
		createSuccess: '創建成功',
		upDateSuccess: '更新成功',
		deleteSuccess: '删除成功',
		saveSuccess: '保存成功',
    webHookHeadConfig: 'WebHook HeaderConfig',
		webHookBodyConfig: 'WebHook BodyConfig',
		EditHeader: '编辑WebHook HeaderConfig',
		EditBody: '編輯WebHook BodyConfig',
		changeMethod: '切換提交方式',
		keyValue: '鍵值對',
		json: 'JSON',
		messagePush: 'messagePush',
    messagePush: '消息推送',
    groupList: '群組列表',
    searcheGroupName: '搜索群組名',
    groupName: '群組名',
    groupNumber: '群組人數',
    email: '郵件',
    enterpriseWechat: '企業微信',
    mpWechat: '微信公眾號',
    action: '操作',
    addMethod: '添加方式',
    providers: '供應商',
    pleaseSelect: '請選擇',
    senderEmail: '發送者郵箱',
    senderPassword: '發送者密碼',
    emailSubject: '郵件主題',
    STMPServer: 'SMTP伺服器',
    STMPPort: 'SMTP端口',
    secure: '加密',
    method: '方法',
    STMPAccount: 'SMTP帳戶',
    STMPPassword: 'SMTP密碼',
    delete: '刪除',
    deleteTip: '您確定要刪除該群組嗎？',
    builtIn: '自訂',
    userdefined: '自訂',
    back: '返回',
    groupMember: '群組成員',
    pleaseEnterEmail: '請輸入新建成員郵箱號',
    sendMethod: '發送方式',
    mainsend: '主送',
    secretsend: '密送',
    carbonCopy: '抄送',
    ethodcopy: '抄送方式',
    deleteNumberTip: '您確定要刪除該群組成員嗎？',
    firstName: '名',
    lastName: '姓',
    dingTalk: '釘釘',
    pleaseInput: '請輸入'
  },
  apiTip: {
    deleteSuccess: '刪除成功',
    deleteFailed: '刪除失敗',
    cancel: '取消',
    save: '保存',
    delete: '刪除',
    ok: '確定',
    close: '關閉'
  }
}

export default fan