<template>
  <div class="member-box">
    <div class="group_title">
      <div class="group_back_all">
        <div class="group_back_title" @click="$emit('cancel', 'click')">
          <i class="el-icon-arrow-left icon_group_left"></i>
          {{ $t('messagePush.back') }}
        </div>
        <div class="group_back_detail" style="margin-left: 20px;">{{ $t('messagePush.groupName') }}<div
            style="color: #323233;">{{ groupItem.groupName }}</div>
        </div>
        <div class="group_back_detail">{{ $t('messagePush.groupNumber') }}<div style="color: #323233;">
            {{ groupItem.groupNum }}</div>
        </div>
        <div class="group_back_detail_img">
          <div v-if="groupItem.groupType !== 'WebHook'">{{ $t('messagePush.sendMethod') }}</div>
          <div v-if="groupItem.groupType === 'email'" class="detail_type">
            <img src="../../../assets/icons/frame/icon-mail-n.svg" alt="">
            <img src="../../../assets/icons/frame/icon-qiyeweixin-d.svg" alt="">
            <img src="../../../assets/icons/frame/icon-weixingongzhong-n.svg" alt="">
            <img src="../../../assets/Microserviceicon/dingding_active(1).svg" alt="">
          </div>
          <div v-else-if="groupItem.groupType === 'enterpriseWechat'" class="detail_type">
            <img src="../../../assets/icons/frame/icon-mail-d.svg" alt="">
            <img src="../../../assets/icons/frame/icon-qiyeweixin-n.svg" alt="">
            <img src="../../../assets/icons/frame/icon-weixingongzhong-n.svg" alt="">
            <img src="../../../assets/Microserviceicon/dingding_active(1).svg" alt="">
          </div>
          <div v-else-if="groupItem.groupType === 'ding'" class="detail_type">
            <img src="../../../assets/icons/frame/icon-mail-d.svg" alt="">
            <img src="../../../assets/icons/frame/icon-qiyeweixin-d.svg" alt="">
            <img src="../../../assets/icons/frame/icon-weixingongzhong-n.svg" alt>
            <img src="../../../assets/Microserviceicon/dingding_normal(1).svg" alt="">
          </div>
          <div v-else-if="groupItem.groupType === 'mpWechat'" class="detail_type">
            <img src="../../../assets/icons/frame/icon-mail-d.svg" alt="">
            <img src="../../../assets/icons/frame/icon-qiyeweixin-d.svg" alt="">
            <img src="../../../assets/icons/frame/icon-weixingongzhong-d.svg" alt="">
            <img src="../../../assets/Microserviceicon/dingding_active(1).svg" alt="">
          </div>
        </div>
      </div>
      <img src="../../../assets/Microserviceicon/addServiceMesh_btn.svg" alt="" @click="addMember" v-show="addButtonFlag"
        class="createFramework">
    </div>
    <div class="member_content">
      <el-table :data="memberData" :header-cell-style="homeHeaderCellSet" :row-style="tableRowClassName"
        class="group_table" :row-key='getRowKeys' ref="msfhomeTableRef">
        <el-table-column :label="$t('messagePush.groupMember')" min-width="25%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              {{ scope.row.firstName }}
            </div>
            <div v-else style="display: flex;" class="name_input">
              <el-input v-model="scope.row.firstName" :placeholder="$t('messagePush.pleaseInput')" ref="firstNameRef"
                style="width: 100%;"></el-input>
           
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="groupItem.groupType === 'enterpriseWechat' || groupItem.groupType === 'ding'"
          label="WebHook URL" min-width="40%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              <el-tooltip :content="scope.row.email" placement="bottom" effect="light"
                style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                <div>{{ scope.row.email }}</div>
              </el-tooltip>
            </div>
            <div v-else style="display: flex;" class="WebHookURL_content">
              <el-input v-model="scope.row.email" placeholder="Please enter WebHook URL" style="width: 55%;"></el-input>
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" @click="saveEditgroup(scope.row)"
                v-if="groupItem.groupType === 'enterpriseWechat'" style="cursor: pointer;">
              <img src="../../../assets/Microserviceicon/cancel.svg" alt="" @click="canclEditgroup(scope.row)"
                v-if="groupItem.groupType === 'enterpriseWechat'" style="cursor: pointer;">
            </div>
          </template>
        </el-table-column>
        <el-table-column v-else-if="groupItem.groupType === 'WebHook'" label="WebHook URL" min-width="40%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              <el-tooltip :content="scope.row.email" placement="bottom" effect="light"
                style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                <div>{{ scope.row.email }}</div>
              </el-tooltip>
            </div>
            <div v-else style="display: flex;" class="WebHookURL_content">
              <el-input v-model="scope.row.email" placeholder="Please enter WebHook URL" style="width: 80%;"></el-input>
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" @click="saveEditgroup(scope.row)"
                v-if="groupItem.groupType === 'enterpriseWechat'">
              <img src="../../../assets/Microserviceicon/cancel.svg" alt="" @click="canclEditgroup(scope.row)"
                v-if="groupItem.groupType === 'enterpriseWechat'">
            </div>
          </template>
        </el-table-column>
        <el-table-column v-else-if="groupItem.groupType === 'email'" :label="$t('messagePush.email')" min-width="30%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              <el-tooltip :content="scope.row.email" placement="bottom" effect="light"
                style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                <div>{{ scope.row.email }}</div>
              </el-tooltip>
            </div>
            <div v-else style="display: flex;">
              <el-input v-model="scope.row.email" :placeholder="$t('messagePush.pleaseEnterEmail')"
                style="width: 55%;"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-else-if="groupItem.groupType !== 'WebHook'" label="OpenID" min-width="55%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              <el-tooltip :content="scope.row.email" placement="bottom" effect="light"
                style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                <div>{{ scope.row.email }}</div>
              </el-tooltip>
            </div>
            <div v-else style="display: flex;" class="WebHookURL_content">
              <el-input v-model="scope.row.email" placeholder="Please enter OpenID" style="width: 55%;"></el-input>
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" @click="saveEditgroup(scope.row)" style="cursor: pointer;">
              <img src="../../../assets/Microserviceicon/cancel.svg" alt="" @click="canclEditgroup(scope.row)" style="cursor: pointer;">
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="groupItem.groupType === 'WebHook'" label="Http Method" min-width="15%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              {{ scope.row.httpMethod }}
            </div>
            <div v-else style="display: flex;align-items: center;" class="add_edit_img">
              <el-select v-model="scope.row.httpMethod" :placeholder="$t('messagePush.pleaseSelect')">
                <el-option :label="'GET'" value="GET"></el-option>
                <el-option :label="'POST'" value="POST"></el-option>
                <el-option :label="'PUT'" value="PUT"></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="groupItem.groupType === 'WebHook'" label="WebHook Header" min-width="20%">
          <template slot-scope="scope">
            <div>
              <span class="line-span" @click="HeaderClick(scope.row, scope.$index)"><span>HeaderConfig</span><span>({{
                Object.keys(scope.row.webHookHeader).length
              }})</span></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="groupItem.groupType === 'WebHook'" label="WebHook Body" min-width="20%">
          <template slot-scope="scope">
            <div>
              <span class="line-span" @click="HeaderClickBody(scope.row, scope.$index)"><span>BodyConfig</span><span>({{
                Object.keys(scope.row.webHookBody).length
              }})</span></span>
            </div>
          </template>
        </el-table-column>
        
        <el-table-column v-if="groupItem.groupType === 'email'" :label="$t('messagePush.ethodcopy')" min-width="20%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              {{
                scope.row.recipientType === 'to' ? $t('messagePush.mainsend') : scope.row.recipientType === 'cc' ?
                  $t('messagePush.carbonCopy') : $t('messagePush.secretsend')
              }}
            </div>
            <div v-else style="display: flex;align-items: center;" class="add_edit_img">
              <el-select v-model="scope.row.recipientType" :placeholder="$t('messagePush.pleaseSelect')"
                style="width: 45%;">
                <el-option :label="$t('messagePush.mainsend')" value="to"></el-option>
                <el-option :label="$t('messagePush.secretsend')" value="bcc"></el-option>
                <el-option :label="$t('messagePush.carbonCopy')" value="cc"></el-option>
              </el-select>
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" @click="saveEditgroup(scope.row)" style="cursor: pointer;">
              <img src="../../../assets/Microserviceicon/cancel.svg" alt="" @click="canclEditgroup(scope.row)" style="cursor: pointer;">
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="groupItem.groupType === 'ding'" :label="`Secret`" min-width="25%">
          <template slot-scope="scope">
            <div v-if="scope.row.config === 'read'">
              <el-tooltip :content="scope.row.recipientType" placement="bottom" effect="light"
                style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                <div>{{ scope.row.recipientType }}</div>
              </el-tooltip>
            </div>
            <div v-else style="display: flex;align-items: center;" class="add_edit_img">
              <el-input v-model="scope.row.recipientType" placeholder="Please enter Secret"
                style="width: 45%;"></el-input>
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" @click="saveEditgroup(scope.row)" style="cursor: pointer;">
              <img src="../../../assets/Microserviceicon/cancel.svg" alt="" @click="canclEditgroup(scope.row)" style="cursor: pointer;">
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('messagePush.action')" width="150px">
          <template slot="header" slot-scope="scope">
              <div :class="'no-center-box'">{{ $t('messagePush.action') }}</div>
              <!-- <div v-if="scope.row.config === 'write' && groupItem.groupType === 'WebHook'" style="display: flex; align-items: center">das</div> -->
            </template>
          <template slot-scope="scope">
            <img src="../../../assets/icons/btn_main_edit.svg" alt="" v-if="scope.row.config === 'read'"
              @click="editMember(scope.row)" class="edit_delete_Icon">
              <img src="../../../assets/icons/frame/icon-32-member-n.svg" alt="" v-if="scope.row.config === 'read' && (groupItem.groupType === 'enterpriseWechat' || groupItem.groupType === 'ding')"
              @click="showPhoneList(scope.row)" class="member_Icon">
              <!-- <img src="../../../assets/icons/sent_normal.png" alt="" v-if="scope.row.config === 'read'"  class="sent_Icon" @click="sendTest(scope.row)"> -->
            <img src="../../../assets/icons/btn_main_delete_normal_blue.svg" alt="" v-if="scope.row.config === 'read'"
              @click="clickDeleteMember(scope.row)" class="delete_Icon">
            <div style="display: flex; align-items: center;">
              <img src="../../../assets/Microserviceicon/saveUpdate.svg" alt="" style="cursor: pointer;"
                @click="saveWebHook(scope.row)"
                v-if="scope.row.config === 'write' && groupItem.groupType === 'WebHook'">
              <img src="../../../assets/Microserviceicon/cancel.svg" alt="" style="cursor: pointer;"
                @click="canclEditgroup(scope.row)"
                v-if="scope.row.config === 'write' && groupItem.groupType === 'WebHook'">
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div v-if="noDataFlag">
            <img class="service-checking-icon" />
            <div>loading...</div>
          </div>
          <div v-else>
            {{ $t('messagePush.nodata') }}
          </div>
        </template>
      </el-table>
      <el-pagination :current-page.sync="memberCurrentPage" :page-size="limit" :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next" :total="totalCount" @size-change="handleMemberSizeChange"
        @current-change="handleMemberCurrentChange">
      </el-pagination>
    </div>
    <!-- 删除 -->
    <el-dialog :visible.sync="deleteMemberVisible" :append-to-body="true" :width="`500px`" :show-close="false" :close-on-click-modal="false"
      style="text-align: left;" class="delete_secret_item">
      <div slot="title" class="dialog-title">
        <span style="font-size: 20px;font-weight: 700;">{{ $t('messagePush.delete') }}</span>
      </div>
      <span class="deleteContentNote">{{ $t('messagePush.deleteNumberTip') }}</span>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex;justify-content: flex-end;">
          <div class="global_button global_cancel_normal" @click="deleteMemberVisible = false">{{ $t('apiTip.cancel') }}
          </div>
          <div class="global_button global_delete_normal" @click="deleteMemberItem">{{ $t('apiTip.delete') }}</div>
        </div>
      </span>
    </el-dialog>
    <!-- 下部分为手机号 -->
     <div class="detailHeader">
      <el-dialog :visible.sync="addPhoneNumber" :width="`800px`" :show-close="true" :close-on-click-modal="false">
        <div slot="title" class="dialog-title">
          <span style="font-size: 20px;font-weight: 700;">{{ $t('messagePush.atTitle') }}</span>
        </div>
        <div style="display: flex;justify-self: start;align-items: center;height: 30px;margin-left: 5px" v-if="groupItem.groupType === 'enterpriseWechat'">
         <el-checkbox v-model="atAllCheck">{{ $t('messagePush.atAll') }}</el-checkbox>
        </div>
        <el-table v-if="!atAllCheck" :data="dataPhoneList" :header-cell-style="homeHeaderCellSet"
          :row-style="tableRowClassName" class="group_table"
          style="min-height: 300px;">
          <el-table-column :label="$t('messagePush.index')" width="150">
            <template slot-scope="scope">
              <span style="padding-left: 3px;">{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('messagePush.phoneNumber')" min-width="450" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'read'">{{ scope.row.value }}</span>
              <el-input v-model="scope.row.value" v-if="scope.row.type === 'edit'">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('messagePush.action')">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;"><img src="../../../assets/icons/btn_main_edit.svg" alt="" v-if="scope.row.type === 'read'"
              @click="editHeaderKey(scope.row)" class="edit_delete_Icon">
              <img src="../../../assets/icons/btn_main_delete_normal_blue.svg" alt="" v-if="scope.row.type === 'read'"
                @click="clickPhoneList(scope.row, scope.$index)" class="delete_Icon"></div>
              <div style="display: flex;align-items: center;"><img src="../../../assets/Microserviceicon/saveUpdate.svg"
                  alt="" style="cursor: pointer;" @click="savePhoneRow(scope.row)"
                  v-if="scope.row.type === 'edit'">
                <img src="../../../assets/Microserviceicon/cancel.svg" alt="" style="cursor: pointer;"
                  @click="canclPhoneRow(scope.row, scope.$index)" v-if="scope.row.type === 'edit'">
              </div>

            </template>
          </el-table-column>
          <el-table-column width="60">
            <template slot="header" slot-scope="scope">
              <img src="../../../assets/Microserviceicon/addServiceMesh_btn.svg" alt="" class="img-add"
                @click="phoneAddClick">
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer" v-if="flagRead === false">
          <div style="display: flex;justify-content: flex-end;">
            <div class="global_button global_cancel_normal" @click="addPhoneNumber = false;flagNoSplit = false">{{ $t('apiTip.cancel') }}
            </div>
            <div class="global_button global_save_normal" @click="savePhoneList()">{{ $t('apiTip.save') }}</div>
          </div>
        </span>
      </el-dialog>
    </div>
    <!-- 上部分为手机号 -->
    <div class="detailHeader">
      <el-dialog :visible.sync="detailHeader" :append-to-body="true" :width="`800px`" :show-close="true" :close-on-click-modal="false">
        <div slot="title" class="dialog-title">
          <span style="font-size: 20px;font-weight: 700;" v-if="flagRead === true">{{$t('messagePush.webHookHeadConfig')}}</span>
          <span style="font-size: 20px;font-weight: 700;" v-if="flagRead === false">{{$t('messagePush.EditHeader')}}</span>
        </div>
        <div style="display: flex;justify-self: start;align-items: center;height: 30px;" v-if="flagRead === false || flagEdit">
          <div style="margin-bottom: 3px;margin-right: 10px;margin-left: 5px;">{{$t('messagePush.changeMethod')}}</div><el-radio-group
            v-model="firstRadio" @change="radioChange">
            <el-radio :label="0">{{$t('messagePush.keyValue')}}</el-radio>
            <el-radio :label="1">{{$t('messagePush.json')}}</el-radio>
          </el-radio-group>
        </div>
        <el-table v-if="firstRadio === 0" :data="detailHeaderData" :header-cell-style="homeHeaderCellSet"
          :row-style="tableRowClassName" class="group_table" :row-key='getRowKeys' ref="msfhomeTableRef"
          style="min-height: 300px;">
          <el-table-column :label="'key'" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'read'" style="padding-left: 3px;">{{ scope.row.key }}</span>
              <el-input v-model="scope.row.key" v-if="scope.row.type === 'edit'">

              </el-input>
            </template>
          </el-table-column>
          <el-table-column :label="'value'" min-width="450" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'read'">{{ scope.row.value }}</span>
              <el-input v-model="scope.row.value" v-if="scope.row.type === 'edit'">

              </el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('messagePush.action')" v-if="flagRead === false">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;"><img src="../../../assets/icons/btn_main_edit.svg" alt="" v-if="scope.row.type === 'read'"
              @click="editHeaderKey(scope.row)" class="edit_delete_Icon">
              <img src="../../../assets/icons/btn_main_delete_normal_blue.svg" alt="" v-if="scope.row.type === 'read'"
                @click="clickDeleteConfig(scope.row, scope.$index)" class="delete_Icon"></div>
              <div style="display: flex;align-items: center;"><img src="../../../assets/Microserviceicon/saveUpdate.svg"
                  alt="" style="cursor: pointer;" @click="saveWebHookHeader(scope.row)"
                  v-if="scope.row.type === 'edit'">
                <img src="../../../assets/Microserviceicon/cancel.svg" alt="" style="cursor: pointer;"
                  @click="canclWebHookHeader(scope.row, scope.$index)" v-if="scope.row.type === 'edit'">
              </div>

            </template>
          </el-table-column>
          <el-table-column width="60" v-if="flagRead === false">
            <template slot="header" slot-scope="scope">
              <img src="../../../assets/Microserviceicon/addServiceMesh_btn.svg" alt="" class="img-add"
                @click="detailHeaderDataAdd">
            </template>
          </el-table-column>
        </el-table>
        <el-input v-if="firstRadio === 1" v-model="headJson" type="textarea" :rows="12" :placeholder="$t('messagePush.pleaseInput')"></el-input>
        <span slot="footer" class="dialog-footer" v-if="flagRead === false">
          <div style="display: flex;justify-content: flex-end;">
            <div class="global_button global_cancel_normal" @click="detailHeader = false; headJson = ''; firstRadio = 0">{{ $t('apiTip.cancel') }}
            </div>
            <div class="global_button global_save_normal" @click="saveConfig()">{{ $t('apiTip.save') }}</div>
          </div>
        </span>
      </el-dialog>
    </div>
    <div class="detailHeader">
      <el-dialog :visible.sync="detailBody" :append-to-body="true" :width="`800px`" :show-close="true" :close-on-click-modal="false">
        <div slot="title" class="dialog-title">
          <span style="font-size: 20px;font-weight: 700;" v-if="flagRead === true">{{$t('messagePush.webHookBodyConfig')}}</span>
          <span style="font-size: 20px;font-weight: 700;" v-if="flagRead === false">{{$t('messagePush.EditBody')}}</span>
        </div>
        <div style="display: flex;justify-self: start;align-items: center;height: 30px;" v-if="flagRead === false || flagEdit">
          <div style="margin-bottom: 5px;margin-right: 10px;margin-left: 5px;">{{$t('messagePush.changeMethod')}}</div><el-radio-group
            v-model="secondRadio" @change="radioChangeTwo">
            <el-radio :label="0">{{$t('messagePush.keyValue')}}</el-radio>
            <el-radio :label="1">{{$t('messagePush.json')}}</el-radio>
          </el-radio-group>
        </div>
        <el-table v-if="secondRadio === 0" :data="detailBodyData" :header-cell-style="homeHeaderCellSet"
          :row-style="tableRowClassName" class="group_table" :row-key='getRowKeys' ref="msfhomeTableRef"
          style="min-height: 300px;">
          <el-table-column :label="'key'" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'read'" style="padding-left: 5px;">{{ scope.row.key }}</span>
              <el-input v-model="scope.row.key" v-if="scope.row.type === 'edit'">

              </el-input>
            </template>
          </el-table-column>
          <el-table-column :label="'value'" min-width="450" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'read'">{{ scope.row.value }}</span>
              <el-input v-model="scope.row.value" v-if="scope.row.type === 'edit'">

              </el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('messagePush.action')" v-if="flagRead === false">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;"><img src="../../../assets/icons/btn_main_edit.svg" alt="" v-if="scope.row.type === 'read'"
              @click="editHeaderKey(scope.row)" class="edit_delete_Icon">
              <img src="../../../assets/icons/btn_main_delete_normal_blue.svg" alt="" v-if="scope.row.type === 'read'"
                @click="clickDeleteConfigTwo(scope.row, scope.$index)" class="delete_Icon"></div>
              <div style="display: flex;align-items: center;"><img src="../../../assets/Microserviceicon/saveUpdate.svg"
                  alt="" style="cursor: pointer;" @click="saveWebHookHeader(scope.row)"
                  v-if="scope.row.type === 'edit'">
                <img src="../../../assets/Microserviceicon/cancel.svg" alt="" style="cursor: pointer;"
                  @click="canclWebHookHeaderTwo(scope.row, scope.$index)" v-if="scope.row.type === 'edit'">
              </div>

            </template>
          </el-table-column>
          <el-table-column width="60" v-if="flagRead === false">
            <template slot="header" slot-scope="scope">
              <img src="../../../assets/Microserviceicon/addServiceMesh_btn.svg" alt="" class="img-add"
                @click="detailBodyDataAdd">
            </template>
          </el-table-column>
        </el-table>
        <el-input v-if="secondRadio === 1" v-model="bodyJson" type="textarea" :rows="12" :placeholder="$t('messagePush.pleaseInput')"></el-input>
        <span slot="footer" class="dialog-footer" v-if="flagRead === false">
          <div style="display: flex;justify-content: flex-end;" >
            <div class="global_button global_cancel_normal" @click="detailBody = false; bodyJson = ''; secondRadio = 0">{{ $t('apiTip.cancel') }}
            </div>
            <div class="global_button global_save_normal" @click="saveBodyConfig()">{{ $t('apiTip.save') }}</div>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
// import messagePushApi from '@/api/messagePushAPI';
import axios from 'axios'
import { Message } from 'element-ui';
export default {
  name: '',
  data() {
    return {
      addButtonFlag: true,
      memberCurrentPage: 1,
      limit: 10,
      offset: 0,
      memberData: [],
      noDataFlag: true,
      deleteMemberVisible: false,
      groupItem: {},
      totalCount: 0,
      idDeleteSendObjectId: null,
      addPhoneNumber: false,
      noAll: true,
      dataPhoneList: [],
      detailHeader: false,
      detailHeaderData: [],
      detailBody: false,
      detailBodyData: [],
      flagRead: false,
      saveIndex: 0,
      firstRadio: 0,
      secondRadio: 0,
      headJson: '',
      bodyJson: '',
      flagEdit: false,
      phoneSaveData: {},
      atAllCheck: false,
      flagNoSplit: false,
      messageUrl: '/api/v1.5',
      cluster: ''
    }
  },
  watch: {

  },
  props: {
    isNeedMember: {
      type: Object,
      required: true,
      default: () => { }
    },
    pushUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  created() {
    const { groupName, groupNum, groupType, groupId } = this.isNeedMember;
    this.groupItem = {
      groupName,
      groupNum,
      groupType,
      groupId
    }
  },
  mounted() {
    //this.cluster=this.$store.state.currentFrameName
    this.getMemberList(10, 0);
  },
  methods: {
    showPhoneList(row) {
      this.addPhoneNumber = true
      this.flagNoSplit = false
      this.dataPhoneList = []
      if (typeof row.postPhoneList.length === 0) {
          this.phoneSaveData = {
          firstName: row.firstName,
          lastName: row.lastName,
          recipientType: row.recipientType,
          target: row.email,
          sendObjectId: row.sendObjectId,
          parameters: {
            mentionedMobileList: []
          }
        }
      } else {
        this.phoneSaveData = {
          firstName: row.firstName,
          lastName: row.lastName,
          recipientType: row.recipientType,
          target: row.email,
          sendObjectId: row.sendObjectId,
          parameters: {
            mentionedMobileList: row.postPhoneList
          }
        }
        row.postPhoneList.forEach((item) => {
          if (item !== '@all') {
            let middleObj = {}
            middleObj.type = 'read'
            middleObj.value = item
            this.dataPhoneList.push(middleObj)
          } else if (item === '@all') {
            this.atAllCheck = true
          }
        })
      }
    },
    editHeaderKey(row) {
      if (this.flagNoSplit === false) {
        row.type = 'edit'
        this.beginValue = row.value
        this.flagNoSplit = true
      } else {
        Message({
          type: 'error',
          message: this.$t('messagePush.cantEditTwo')
        })
        return
      }
    },
    editMember(row) {
      if (this.addButtonFlag === false) {
        Message({
          message: this.$t('messagePush.addCantEdit'),
          type: 'error'
        })
        return
      }
      if (this.flagEdit === true) {
        Message({
          message: this.$t('messagePush.cantEditTwo'),
          type: 'error'
        })
        return
      }
      row.config = 'write'
      this.flagEdit = true
    },
    radioChange(label) {
      console.log('label', label)
      if (label === 1) {
        if (JSON.stringify(this.memberData[this.saveIndex].webHookHeader) !== '{}') {
          this.headJson = JSON.stringify(this.memberData[this.saveIndex].webHookHeader, null, 3)
        } else {
          this.headJson = ''
        }
        
      }
    },
    radioChangeTwo(label) {
      console.log('label', label)
      if (label === 1) {
        if (JSON.stringify(this.memberData[this.saveIndex].webHookBody) !== '{}') {
          this.bodyJson = JSON.stringify(this.memberData[this.saveIndex].webHookBody, null, 3)
        } else {
          this.bodyJson = ''
        }
        
      }
    },
    saveConfig() {
      if (this.firstRadio === 0) {
        console.log('this.detailHeaderData', this.detailHeaderData)
        this.memberData[this.saveIndex].webHookHeader = {}
        this.detailHeaderData.forEach((item) => {
          if (item.type === 'read') {
            let obj = {}
            if (item.value === 'true') {
              item.value = true
            } else if (item.value === 'false') {
              item.value = false
            }
            if (isNaN(item.value) === false && item.value !== '' && item.value !== false) {
              item.value = Number(item.value)
            }
            try {
            if (/^\{/.test(item.value)) {
              console.log('item.value1', item.value)
              const toObj = JSON.parse(item.value)
              if (toObj && typeof toObj === 'object') {
                item.value = toObj
              } else {
                Message({
                  message: this.$t('messagePush.valueErr'),
                  type: 'error'
                })
              }
              console.log('item.value2', item.value)
            }
            } catch (err) {
              
            }
            obj[`${item.key}`] = item.value
            Object.assign(this.memberData[this.saveIndex].webHookHeader, obj)
          }
          console.log('this.saveIndex', this.saveIndex, this.memberData)
        })
        this.memberData.push({
          firstName: 'dad',
          webHookHeader: {},
          webHookBody: {}
        })
        this.memberData.splice(this.memberData.length - 1, 1)
        this.detailHeader = false
      } else {
        try {
          const toObj = JSON.parse(this.headJson) // json字符串转对象
          /*
              判断条件 1. 排除null可能性 
                       2. 确保数据是对象或数组
          */
          if (toObj && typeof toObj === 'object') {
            Message({
              message: '保存成功',
              type: 'success'
            });
            this.memberData[this.saveIndex].webHookHeader = toObj
            this.memberData.push({
              firstName: 'dad',
              webHookHeader: {},
              webHookBody: {}
            })
            this.memberData.splice(this.memberData.length - 1, 1)
            this.detailHeader = false
            console.log('toObj', toObj)
          } else {
            Message({
              message: 'JSON字符串格式错误',
              type: 'error'
            });
          }
        } catch (err) {
          Message({
            message: 'JSON字符串格式错误',
            type: 'error'
          });
        }
      }
    },
    savePhoneList() {
      let arr = []
      let flagPost = true
      if (this.dataPhoneList.length !== 0) {
        this.dataPhoneList.forEach((item) => {
          if (item.type === 'edit') {
            flagPost = false
          } else {
            arr.push(item.value)
          }
        })
      }
      if (flagPost === false) {
         Message({
            message: this.$t('messagePush.beforeSave'),
            type: 'error'
          })
        return
      }
      if (this.groupItem.groupType === 'enterpriseWechat') {
        this.phoneSaveData.parameters.mentionedMobileList = arr
      } else if (this.groupItem.groupType === 'ding') {
        this.phoneSaveData.parameters.atMembers = arr
      }
      if (this.groupItem.groupType === 'enterpriseWechat' && this.atAllCheck === true) {
        arr.push('@all')
        this.phoneSaveData.parameters.mentionedMobileList = arr
      }
      let body = [this.phoneSaveData]
      this.addPhoneNumber = false
      this.updateMember(body)
    },
    saveBodyConfig() {
      if (this.secondRadio === 0) {
        this.memberData[this.saveIndex].webHookBody = {}
        this.detailBodyData.forEach((item) => {
          if (item.type === 'read') {
            let obj = {}
            if (item.value === 'true') {
              item.value = true
            } else if (item.value === 'false') {
              item.value = false
            }
            if (isNaN(item.value) === false && item.value !== '' && item.value !== false) {
              item.value = Number(item.value)
            }
            try {
            if (/^\{/.test(item.value)) {
              console.log('item.value1', item.value)
              const toObj = JSON.parse(item.value)
              if (toObj && typeof toObj === 'object') {
                item.value = toObj
              } else {
                Message({
                  message: this.$t('messagePush.valueErr'),
                  type: 'error'
                })
              }
              console.log('item.value2', item.value)
            }
            } catch (err) {

            }
            obj[`${item.key}`] = item.value
            Object.assign(this.memberData[this.saveIndex].webHookBody, obj)
          }
          console.log('this.saveIndex', this.saveIndex, this.memberData)
        })
        this.memberData.push({
          firstName: 'dad',
          webHookHeader: {},
          webHookBody: {}
        })
        this.memberData.splice(this.memberData.length - 1, 1)
        this.detailBody = false
      } else {
        try {
          const toObj = JSON.parse(this.bodyJson) // json字符串转对象
          /*
              判断条件 1. 排除null可能性 
                       2. 确保数据是对象或数组
          */
          if (toObj && typeof toObj === 'object') {
            Message({
              message: '保存成功',
              type: 'success'
            });
            this.memberData[this.saveIndex].webHookBody = toObj
            this.memberData.push({
              firstName: 'dad',
              webHookHeader: {},
              webHookBody: {}
            })
            this.memberData.splice(this.memberData.length - 1, 1)
            this.detailBody = false
            console.log('toObj', toObj)
          } else {
            Message({
              message: this.$t('messagePush.jsonErr'),
              type: 'error'
            });
          }
        } catch (err) {
          Message({
            message: this.$t('messagePush.jsonErr'),
            type: 'error'
          });
        }
      }

    },
    HeaderClick(row, index) {
      console.log('row', row)
      this.detailHeaderData = []
      if (row.config === 'read') {
        this.flagRead = true
      } else {
        this.flagRead = false
      }
      this.saveIndex = index
      if (row.webHookHeader) {
        if (Object.keys(row.webHookHeader).length !== 0) {
          for (let key in row.webHookHeader) {
            let middleObj = {}
            middleObj.key = key
            middleObj.value = row.webHookHeader[key]
            // if (row.config === 'read') {
            //   middleObj.type = row.config
            // } else {
            //   middleObj.type = 'edit'
            // }
            middleObj.type = 'read'
            this.detailHeaderData.push(middleObj)


          }
        }
      }
      this.flagNoSplit = false
      this.detailHeader = true
    },
    HeaderClickBody(row, index) {
      console.log('row', row)
      this.detailBodyData = []
      if (row.config === 'read') {
        this.flagRead = true
      } else {
        this.flagRead = false
      }
      this.saveIndex = index
      if (row.webHookBody) {
        if (Object.keys(row.webHookBody).length !== 0) {
          for (let key in row.webHookBody) {
            let middleObj = {}
            middleObj.key = key
            middleObj.value = row.webHookBody[key]
            // if (row.config === 'read') {
            //   middleObj.type = row.config
            // } else {
            //   middleObj.type = 'edit'
            // }
            middleObj.type = 'read'
            this.detailBodyData.push(middleObj)


          }
        }
      }
      this.detailBody = true
      this.flagNoSplit = false
    },
    phoneAddClick() {
      if (this.dataPhoneList.length !== 0) {
        console.log('this.dataPhoneList', this.dataPhoneList)
        let index = this.dataPhoneList.findIndex((item) => {
          return item.type === 'edit'
        })
        console.log('index', index)
        if (index === -1) {
          let middleArr = {}
          middleArr.value = '',
          middleArr.type = 'edit'
          this.dataPhoneList.push(middleArr)
        } else {
          Message({
            message: this.$t('messagePush.cantEditTwo'),
            type: 'error'
          })
        }
      } else {
        let middleArr = {}
        middleArr.value = '',
        middleArr.type = 'edit'
        this.dataPhoneList.push(middleArr)
      } 
    },
    detailHeaderDataAdd() {
      if (this.detailHeaderData.length !== 0) {
        console.log('this.detailHeaderData', this.detailHeaderData)
        let index = this.detailHeaderData.findIndex((item) => {
          return item.type === 'edit'
        })
        console.log('index', index)
        if (index === -1) {
          let middleArr = {}
          middleArr.key = '',
          middleArr.value = '',
          middleArr.type = 'edit'
          this.detailHeaderData.push(middleArr)
        } else {
          Message({
            message: this.$t('messagePush.cantEditTwo'),
            type: 'error'
          })
        }
      } else {
        let middleArr = {}
        middleArr.key = '',
        middleArr.value = '',
        middleArr.type = 'edit'
        this.detailHeaderData.push(middleArr)
      } 
      
    },
    detailBodyDataAdd() {
      if (this.detailBodyData.length !== 0) {
        console.log('this.detailBodyData', this.detailBodyData)
        let index = this.detailBodyData.findIndex((item) => {
          return item.type === 'edit'
        })
        console.log('index', index)
        if (index === -1) {
          let middleArr = {}
          middleArr.key = '',
          middleArr.value = '',
          middleArr.type = 'edit'
          this.detailBodyData.push(middleArr)
        } else {
          Message({
            message: this.$t('messagePush.cantEditTwo'),
            type: 'error'
          })
        }
      } else {
          let middleArr = {}
          middleArr.key = '',
          middleArr.value = '',
          middleArr.type = 'edit'
          this.detailBodyData.push(middleArr)
      } 
      
    },
    getConfigNumber(row, type) {
      console.log('执行')
      if (type === 'Header') {
        if (row.webHookHeader) {
          const arr = Object.keys(row.webHookHeader)
          return arr.length
        }

      }
      if (type === 'Body') {
        if (row.webHookBody) {
          const arr = Object.keys(row.webHookBody)
          return arr.length
        }
      }
    },
    getMemberList(limit, offset) {
      this.memberData = [];
      this.noDataFlag = true;
      let url = `${this.messageUrl}/Group/members/${this.groupItem.groupId}?limit=${limit}&offset=${offset}&cluster=${this.cluster}`;
      axios.get(url).then(res => {
        let i = 1;
        console.log('getMemberList', res)
        this.totalCount = res.data.totalCount;
        res.data.sendList.map(item => {
          let middleObj = {}
          middleObj.id = i++
          middleObj.firstName = item.firstName
          middleObj.lastName = item.lastName
          middleObj.config = 'read'
          middleObj.recipientType = item.recipientType
          middleObj.sendObjectId = item.sendObjectId
          middleObj.email = item.target
          middleObj.postPhoneList = []
          middleObj.httpMethod = ''
          if (item.parameters && JSON.stringify(item.parameters) === '{}') {
            middleObj.webHookHeader = {}
            middleObj.webHookBody = {}
          } else if (item.parameters && JSON.stringify(item.parameters) !== '{}') {
            if (item.parameters && item.parameters.webHookHeader) {
            middleObj.webHookHeader = item.parameters.webHookHeader
          } else if (typeof item.parameters.webHookHeader === 'undefined') {
            middleObj.webHookHeader = {}
          }
          if (item.parameters && item.parameters.webHookBody) {
            middleObj.webHookBody = item.parameters.webHookBody
          } else if (typeof item.parameters.webHookBody === 'undefined') {
            middleObj.webHookBody = {}
          }
            if (item.parameters && item.parameters.atMembers) {
              middleObj.postPhoneList = item.parameters.atMembers
            } else if (item.parameters && item.parameters.mentionedMobileList) {
              middleObj.postPhoneList = item.parameters.mentionedMobileList
            } else {
              middleObj.postPhoneList = []
            }
            if (item.parameters && item.parameters.method) {
              middleObj.httpMethod = item.parameters.method
            }
          }
          
          
          this.memberData.push(middleObj)
        })
        this.groupItem.groupNum = res.data.totalCount;
        this.noDataFlag = false;
      }).catch(error => {
        console.log(error);
        this.noDataFlag = false;
      })
    },
    createMember(data, row) {
      let url = `${this.messageUrl}/Group/members/${this.groupItem.groupId}?cluster=${this.cluster}`;
      axios.post(url, data).then(res => {
        this.getMemberList(this.limit, this.offset);
        this.firstRadio = 0
        this.secondRadio = 0
        this.headJson = ''
        this.bodyJson = ''
        this.detailHeaderData = []
        this.detailBodyData = []
        Message({
          message: this.$t('messagePush.createSuccess'),
          type: 'success'
        });
        this.canclEditgroup(row);
      }).catch(error => {
        console.log(error);
        Message({
          message: error.data.errMsg,
          type: 'error'
        });
        this.canclEditgroup(row);
      })
    },
    updateMember(data, row) {
      let url = `${this.messageUrl}/Group/members/${this.groupItem.groupId}?cluster=${this.cluster}`;
      axios.put(url, data).then(res => {
        this.getMemberList(this.limit, this.offset);
        this.firstRadio = 0
        this.secondRadio = 0
        this.headJson = ''
        this.bodyJson = ''
        this.atAllCheck = false
        this.detailHeaderData = []
        this.detailBodyData = []
        Message({
          message: this.$t('messagePush.upDateSuccess'),
          type: 'success'
        });
        // this.canclEditgroup(row);
        this.flagEdit = false
      }).catch(error => {
        console.log(error);
        Message({
          message: error.data.errMsg,
          type: 'error'
        });
        // this.canclEditgroup(row);
      })
    },
    deleteMember(list) {
      let url = `${this.messageUrl}/Group/members/${this.groupItem.groupId}?cluster=${this.cluster}`;
      let params = {
        list
      };
      axios.delete(url, { data: params }, { withCredentials: true }).then(res => {
        this.getMemberList(this.limit, this.offset);
        Message({
          message: this.$t('messagePush.deleteSuccess'),
          type: 'success'
        });
        this.deleteMemberVisible = false;
      }).catch(error => {
        console.log(error);
        Message({
          message: error.data.errMsg,
          type: 'error'
        });
        this.deleteMemberVisible = false;
      })
    },
    handleMemberSizeChange(val) {
      this.limit = val;
      this.memberCurrentPage = 1
      this.getMemberList(this.limit, 0);
    },
    handleMemberCurrentChange(val) {
      this.offset = this.limit * (val - 1);
      console.log('执行')
      this.memberCurrentPage = val;
      this.getMemberList(this.limit, this.offset);
    },
    homeHeaderCellSet({ row, column, rowIndex, columnIndex }) {
      return 'color: #006296;font-weight: 700;font-size:12px;border-color:#006296'
    },
    tableRowClassName({ row, rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'background-color: #efefef'
      } else {
        return 'background-color: #fafafa'
      }
    },
    addMember() {
      if (this.flagEdit) {
        Message({
          message: this.$t('messagePush.addCantEdit'),
          type: 'error'
        })
        return
      }
      this.addButtonFlag = false;
      let url = this.groupItem.groupType === 'email'
      this.memberData.push({
        id: this.memberData.length + 1,
        email: '',
        config: 'write',
        firstName: '',
        lastName: '',
        recipientType: '',
        webHookHeader: {},
        webHookBody: {},
        postPhoneList: [],
        httpMethod: ''
      })
      this.$nextTick(() => {
        this.$refs.firstNameRef.focus();
      })
    },
    getRowKeys(row) {//行数据的key，用于优化table的渲染
      return row.id;
    },
    saveEditgroup(row) {
      
      if (this.flagEdit) {
        let body 
        if (row.postPhoneList.length === 0) {
          body = [{
            firstName: row.firstName,
            lastName: row.lastName,
            recipientType: row.recipientType,
            target: row.email,
            sendObjectId: row.sendObjectId
          }]
        } else if (this.groupItem.groupType === 'enterpriseWechat' && row.postPhoneList.length !== 0){
          body = [{
            firstName: row.firstName,
            lastName: row.lastName,
            recipientType: row.recipientType,
            target: row.email,
            sendObjectId: row.sendObjectId,
            parameters: {
              mentionedMobileList: row.postPhoneList
            }
          }]
        } else if (this.groupItem.groupType === 'ding' && row.postPhoneList.length !== 0) {
          body = [{
            firstName: row.firstName,
            lastName: row.lastName,
            recipientType: row.recipientType,
            target: row.email,
            sendObjectId: row.sendObjectId,
            parameters: {
              atMembers: row.postPhoneList
            }
          }]
        }     
        this.updateMember(body, row)
      } else {
        let body = [{
        firstName: row.firstName,
        lastName: row.lastName,
        recipientType: row.recipientType,
        target: row.email
      }]
      this.createMember(body, row);
      }
      
    },
    saveWebHook(row) {
      console.log(row, 'row')
      if (this.flagEdit) {
        let body = [{
        firstName: row.firstName,
        lastName: row.lastName,
        // recipientType: row.recipientType,
        target: row.email,
        parameters: {
          "webHookHeader": row.webHookHeader,
          "webHookBody": row.webHookBody,
          "method": row.httpMethod
        },
        sendObjectId: row.sendObjectId
      }]
        if (row.httpMethod === '') {
          Message({
            message: this.$t('messagePush.cantEmpty'),
            type: 'error'
          })
          return
        }
        this.updateMember(body, row)
      } else {
        let body = [{
        firstName: row.firstName,
        lastName: row.lastName,
        // recipientType: row.recipientType,
        target: row.email,
        parameters: {
          "webHookHeader": row.webHookHeader,
          "webHookBody": row.webHookBody,
          "method": row.httpMethod
        }
      }]
      if (row.httpMethod === '') {
          Message({
            message: this.$t('messagePush.cantEmpty')
          })
          return
        }
      this.createMember(body, row);
      }
      
      
    },
    saveWebHookHeader(row) {
      if (row.key !== '' && row.value !== '') {
        row.type = 'read'
        this.flagNoSplit = false
      } else {
        Message({
          message: 'key或value不能为空',
          type: 'error',
          showClose: false
        });
      }

    },
    canclEditgroup(row) {
      if (this.flagEdit) {
        // console.log('执行')
        row.config = 'read'
        this.flagEdit = false
        this.getMemberList(this.limit, this.offset);
        return
      }
      this.memberData.map((item, index) => {
        if (row.id === item.id) {
          this.memberData.splice(index, 1)
        }
      })
      this.addButtonFlag = true;
    },
    canclPhoneRow(row, index) {
      if (this.flagNoSplit === true) {
        row.value = this.beginValue
        row.type = 'read'
        this.flagNoSplit = false
      } else {
        this.dataPhoneList.splice(index, 1)
      }
      
    },
    canclWebHookHeader(row, index) {
      if (this.flagNoSplit === true) {
        row.value = this.beginValue
        row.type = 'read'
        this.flagNoSplit = false
      } else {
        this.detailHeaderData.splice(index, 1)
      }
      
    },
    canclWebHookHeaderTwo(row, index) {
      if (this.flagNoSplit === true) {
        row.value = this.beginValue
        row.type = 'read'
        this.flagNoSplit = false
      } else {
        this.detailBodyData.splice(index, 1)
      }
      
    },
    clickPhoneList(row, index) {
      // if (this.flagNoSplit === true) {
      //   row.value = this.beginValue
      //   row.type = 'read'
      //   this.flagNoSplit = false
      // } else {
        this.dataPhoneList.splice(index, 1)
      // }
      
    },
    sendTest(row) {
      console.log('row', row)
      let url = `${this.messageUrl}/Groups/send?cluster=${this.cluster}`;
      let data = [{
        groupId: this.groupItem.groupId,
        message: 'This is a test message'
      }]
      axios.post(url, data).then((res) => {
        console.log('res', res)
        Message({
          message: this.$t('messagePush.sendSuccess'),
          type: 'success',
          showClose: false
        })
      }).catch((err) => {
        console.log('err', err.data.error.message)
        Message({
          message: err.data.error.message,
          type: 'error',
          showClose: false
        })
      })
    },
    savePhoneRow(row) {
      if (row.value !== '') {
        row.type = 'read'
        this.flagNoSplit = false
      } else {
        Message({
          message: 'key或value不能为空',
          type: 'error',
          showClose: false
        });
      }

    },
    clickDeleteConfig(row, index) {
      // if (this.flagNoSplit === true) {
      //   row.value = this.beginValue
      //   row.type = 'read'
      //   this.flagNoSplit = false
      // } else {
        this.detailHeaderData.splice(index, 1)
      // }
    },
    clickDeleteConfigTwo(row, index) {
      //  if (this.flagNoSplit === true) {
      //   row.value = this.beginValue
      //   row.type = 'read'
      //   this.flagNoSplit = false
      // } else {
        console.log('是否执行我')
        this.detailBodyData.splice(index, 1)
      // }
    },
    clickDeleteMember(row) {
      this.idDeleteSendObjectId = row.sendObjectId;
      this.deleteMemberVisible = true;
    },
    deleteMemberItem() {
      this.deleteMember([this.idDeleteSendObjectId])
    }
  }
}
</script>
<style>
.el-tooltip__popper.is-dark {
  max-width: 500px;
}
</style>
<style scoped>
.center-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-center-box {
  display: flex;
  align-items: center;
}
.member_Icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.member_Icon:hover {
  content: url('../../../assets/icons/frame/icon-32-member-h.svg');
}

.member_Icon:active {
  content: url('../../../assets/icons/frame/icon-32-member-a.svg');
}
.sent_Icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.sent_Icon:hover {
  content: url('../../../assets/icons/sent_hover.png');
}
.sent_Icon:active {
  content: url('../../../assets/icons/sent_press.png');
}
.edit_delete_Icon,
.delete_Icon {
}
.edit_delete_Icon:hover {
  cursor: pointer;
  content: url("../../../assets/icons/edit-normal/btn-main-edit-2-hover.svg");
}
.delete_Icon:hover {
  cursor: pointer;
  content: url("../../../assets/icons/btn_main_delete_hover.svg");
}
.edit_delete_Icon:active {
  content: url("../../../assets/icons/edit-normal/btn-main-edit-2-active.svg");
}
.global_save_normal {
  background-color: transparent;
  color: #008cd6 !important;
}

/* .global_save_normalsable {
  background-color: transparent;
  color: grey;
  cursor: not-allowed;
} */

.global_save_normal:hover {
  background-color: #f5f5f5;
}

.global_save_normal:active {
  background-color: #008cd6;
  color: #fff !important;
}
* {
  font-family: Roboto;
}

.img-add {
  cursor: pointer;
}

.img-add:hover {
  /* height: 24px; */
  content: url('../../../assets/icons/btn_main_create_hover.svg');
}

.img-add:active {
  /* height: 24px; */
  content: url('../../../assets/icons/btn_main_create_active_blue.svg');
}

.detailHeader /deep/ .el-dialog__footer {
  padding: 0 0 0;
  border-top: 1px solid #c8c8c8;
}

.detailHeader /deep/ .el-dialog__body {
  padding: 10px 10px;
}

.detailHeader /deep/ .el-input__inner {
  padding: 0 5px;
}

.detailHeader /deep/ .el-table td {
  padding: 4px 0px;
  height: 32px;
}

.detailHeader /deep/ .el-table th {
  padding: 4px 0px;
}

.detailHeader /deep/ .el-dialog__header {
  padding: 19px 12px 10px;
  text-align: start;
  border-bottom: 1px solid #c8c8c8;
}

.line-span {
  color: #108ee9;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #108ee9;
}

.global_button {
  width: 110px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto;
  color: #787878;
  text-align: center;
  cursor: pointer;
}

.global_delete_normal {
  color: #ce2f2f
}

.global_delete_normal:active {
  background-color: #ce2f2f;
  color: #fff
}

.global_cancel_normal:active {
  background-color: #787878;
  color: #fff
}

.member-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.group_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 38px 0 23px;
  border-bottom: 1px solid #ccc;
}

.group_back_all {
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 72px;
  font-weight: bold;
  color: #006296;
}

.group_back_title {
  width: 141px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  background-color: #008cd6;
  cursor: pointer;
}

.icon_group_left {
  float: left;
  line-height: 36px;
}

.group_back_detail {
  width: 140px;
}

.group_back_detail_img {
  width: 140px;
  margin-top: 12px;
}

.group_back_detail_img img {
  width: 32px;
  height: 32px;
}

.member_content {
  flex-grow: 1;
  /* height: 100%; */
  margin: 26px 38px 10px 23px;
  padding: 0 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
}

.member_content /deep/ .el-table td,
.el-table th {
  padding: 0;
  height: 48px;
  overflow: auto;
}

/deep/ .cell:hover {
  background-color: transparent;
}

.member_content /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: transparent;
}

.member_content /deep/ .el-pagination {
  height: 48px;
  padding: 10px 0 0 0;
  background-color: #fff;
}

.group_table /deep/ .el-input__inner {
  height: 28px;
}

.member-box::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}

.member-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #C8C8C8;
}

.delete_secret_item /deep/ .el-dialog__body {
  padding: 16px 20px
}

.delete_secret_item /deep/ .el-dialog__footer {
  padding: 0 0.10416666rem;
  height: 49px;
  border-top: 1px solid #ccc;
}

.cancel_hover_show,
.delete_secret_show {
  height: 48px;
}

.cancel_hover_show:hover {
  cursor: pointer;
  height: 48px;
  content: url('../../../assets/Microserviceicon/btn-main-word-cancel-hover.svg');
}

.cancel_hover_show:active {
  height: 48px;
  content: url('../../../assets/Microserviceicon/btn-main-word-cancel-active.svg');
}

.delete_secret_show:hover {
  height: 48px;
  cursor: pointer;
  content: url('../../../assets/Microserviceicon/btn-main-word-delete-hover.svg');
}

.delete_secret_show:active {
  height: 48px;
  content: url('../../../assets/Microserviceicon/btn-main-word-delete-active.svg');
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.service-checking-icon {
  width: 32px;
  height: 32px;
  vertical-align: bottom
}

.service-checking-icon {
  content: url('../../../assets/icons/img_loading1.svg');
  transition: transform 1s;
  animation: rotation 1.3s linear infinite;
}

.el-icon-arrow-left:before {
  color: #fff;
  font-weight: 700;
}

.add_edit_img img {
  cursor: pointer;
}

.createFramework,
.delete_Icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.createFramework:hover {
  width: 32px;
  height: 32px;
  content: url('../../../assets/icons/btn_main_create_hover.svg');
}

.createFramework:active {
  width: 32px;
  height: 32px;
  content: url('../../../assets/icons/btn_main_create_active_blue.svg');
}

.delete_Icon:hover {
  content: url('../../../assets/icons/btn_main_delete_hover.svg');
}

.delete_Icon:active {
  content: url('../../../assets/icons/btn_main_delete_active.svg');
}

.detail_type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #C8C8C8;
}
</style>